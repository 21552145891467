import React, { useState } from "react";
import { RadioButton } from "jobin-ui-components";

import MainModal from "./MainModal";
import { DayPickerRangeController } from "react-dates";
import Button from "../button/Button";

const SelectDateModal = ({ isVisible, onClose, saveDate, selectedDate_,business }) => {
    const style = {
        dateView: {
            display: "flex",
            flex: 1,
            justifyContent: "center",
            marginTop: 32,
            marginBottom: 16,
            flexDirection: "column",
            alignItems: "center"
        },
        appointmentType: {
            display: "flex",
            justifyContent: "center",
            marginBottom: 32,
            alignItems: "center"
        }
    };

    const [selectedDate, setSelectedDate] = useState(selectedDate_);
    const [loading, setLoading] = useState(selectedDate_);
    const [focused, setFocused] = useState("startDate");
    const [diagnosis, setDiagnosis] = useState(false);
    const [execution, setExecution] = useState(false);

    const filterDate = startDate => {
        setSelectedDate(startDate.utc().set('hour', 12));
    };

    const onFocusChangeDate = focusedInput => {
        // Force the focusedInput to always be truthy so that dates are always selectable
        setFocused(!focusedInput ? "startDate" : focusedInput);
    };

    const handleSaveDate = () => {
        setLoading(true);
        saveDate(new Date(selectedDate.format()), diagnosis ? "diagnosis" : execution ? "execution" : null);
    };

    const handleTypeSelected = (type) => {
        switch (type) {
            case "execution":
                setDiagnosis(false)
                setExecution(true)
                break;

            case "diagnosis":
                setDiagnosis(true)
                setExecution(false)
                break;

            default:
                break;
        }
    };

    return (
        <MainModal
            gigant={true}
            title={"Crea una cita"}
            subtitle={
                "Selecciona el día que el Woller realizará el servicio al cliente."
            }
            isVisible={isVisible}
            onClose={onClose}
            style={{width:"30%"}}
        >
            <div style={style.dateView}>
               {business == "M7t1A74luq" && <div style={style.appointmentType}>
                    <RadioButton
                        item={{
                            title: "Cita de diagnostico",
                            selected: diagnosis
                        }}
                        onClick={() => {
                            handleTypeSelected("diagnosis")
                        }}
                    /> <RadioButton
                        item={{
                            title: "Cita de ejecución",
                            selected: execution
                        }}
                        onClick={() => {
                            handleTypeSelected("execution")
                        }}
                    />
                </div>}
                <DayPickerRangeController
                    onDatesChange={({ startDate }) => filterDate(startDate)}
                    onFocusChange={onFocusChangeDate}
                    focusedInput={focused}
                    startDate={selectedDate}
                    endDate={selectedDate}
                    numberOfMonths={1}
                    hideKeyboardShortcutsPanel={true}
                    isOutsideRange={date => date.isBefore(new Date(), "day")}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginTop: 24
                }}
            >
                <Button
                    style={{ width: 85, height: 44 }}
                    buttonText="Guardar"
                    loading={loading}
                    disabled={selectedDate ? false : true}
                    onClick={handleSaveDate}
                />
            </div>
        </MainModal>
    );
};

export default SelectDateModal;
