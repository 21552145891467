import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { Button } from "jobin-ui-components";
import  StateModal  from "../components/modal/StateModal";
import ReviewModal from "../components/modal/ReviewModal";
import WollerState from "../components/search/wollerState";
import { useStateValue } from "../state/context";




const JobCell = props => {
    const [{ user,currentSession,agent }, dispatch] = useStateValue();
    const [isStateModal, setIsStateModal] = useState(false);
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [wollers, setWollers] = useState([]);

    const style = {
        container: {
            display: "flex",
            flexDirection: "column",
            border: "2px solid var(--soft-grey)",
            paddingTop: 16,
            paddingBottom: 20,
            paddingRight: 16,
            paddingLeft: 16,
            borderRadius: 4,
            whiteSpace: "nowrap",
            backgroundColor: "#fff"
        },
        jobTypeLabel:{
            border: '2px solid var(--black-wolly-darker)',
            borderRadius: 4,
            padding: '0px 3px',
            marginRight: 8,
            fontSize: 12,
            fontWeight: 700,
            color: 'var(--black-wolly-darker)'
        },
        icon: {
            height: 16,
            width: 16,
            marginRight: 8
        },
        dataCell: {
            display: "flex",
            height: 28,
            alignItems: "center",
            fontSize: 15
        },
        typeLabel: {
            color: "var(--black-wolly-darker)",
            fontWeight: 700,
            fontSize: 13,
            marginBottom: 4
        },
        titleLabel: {
            fontWeight: 700,
            fontSize: 18,
            marginBottom: 5,
            textOverflow: "ellipsis",
            overflow: "hidden"
        },
        priceLabel: {
            marginTop: 8,
            fontSize: 20,
            fontWeight: 700,
            marginBottom: -4
        },
        footer: {
            display: "flex",
            flex: 1,
            alignItems: "center"
        },
        priceView: {
            display: "flex",
            flex: 1,
            flexDirection: "column"
        },
        addressText: {
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        joberView: {
            display: "flex",
            flex: 1,
            marginTop: 8
        },
        joberIcon:{
            height: 36,
            width: 36,
            borderRadius: 18,
            marginRight: 6
        },
        bussinesIcon:{
            height: "40px",
            width: "40px",
            marginLeft: "84%",
            marginTop: "-32px",
            position: "absolute",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: 'no-repeat',
        }
        
    };


    const ShowIsStateModal = ()=>{
        setIsStateModal(true)
    }
    const ShowIsReviewModal = ()=>{
        setShowReviewModal(true)
    }
    const reviewSaved = async () => {
       const result = await Jobin.History.getHistoryOfJob(props.job.id);
        if (result.status === "ok") {
            dispatch({
                type: "JOB_DETAILS_JOB",
                historyArray: result.result
            });
            props.refresh({})
        }
        setShowReviewModal(false);
    };
    const budgetAsUser = (woller,budgets)=>{
        if(budgets !== undefined){
           let result =  budgets.filter((budget)=>{
                return woller.id  === budget.userId
                    
            })

           return result !== undefined ? result[0]:null
        }else{
            return null
        }
    }
    
    return (
        <div style={{...style.container, ...props.style}}>
            {isStateModal &&
                <StateModal
                    isVisible={isStateModal}
                    data={props.job}
                    onClose={()=>setIsStateModal(false)}
                    refreshJobs ={props.refresh}
                />
            }
            {showReviewModal && (
                    <ReviewModal
                        isVisible={showReviewModal}
                        onClose={() => setShowReviewModal(false)}
                        job={props.job}
                        // jober={joberFinish}
                        reviewSaved={reviewSaved}
                        onReviewSaved={setShowReviewModal}
                    />
                )}
            <div style={{paddingBottom: 6,position:"relative"}}>
                <label style={style.jobTypeLabel}>
                    {props.job.jobType === "fixed" || props.job.jobType === "transport" ?
                            props.job.jobID :
                            "LEAD"
                    }
                </label>
                <label style={style.typeLabel}>
                    {agent.dept != "ConstructionCompany" ? props.job?.type?.toUpperCase():props.job?.guild ? props.job?.guild?.get("Name").toUpperCase():"SIN CLASIFICAR"}
                </label>
                {props.job.business && props.job.business.get("Logo") && (<div style={{background:"url("+props.job.business.get("Logo").url()+")", ...style.bussinesIcon}}></div>)}
            
            </div>
            <label style={style.titleLabel}>{props.job.title}</label>
            <div style={style.dataCell}>
                <img
                    style={style.icon}
                    alt=''
                    src={require("../assets/location-tag.svg")}
                />
                <label style={style.addressText}>{props.job.address}</label>
            </div>
            <div style={style.dataCell}>
                <img
                    style={style.icon}
                    alt=''
                    src={require("../assets/calendar.svg")}
                />
                <label>
                    {Jobin.Date.toLargeFormat(props.job.createdAt).result}
                </label>
            </div>
            <div style={style.footer}>
                {agent?.dept != "ConstructionCompany" && props.job.jobType === 'lead' ?
                   ( <div style={style.joberView}>
                        <WollerState 
                            wollers={props.job.suscribedWollers}
                            budget={props.job.Budget}
                            totalSlots={props.job.totalSlots} />
                    </div>)
                : agent.dept != "ConstructionCompany" ?
                    <div style={style.priceView}>
                        <label style={style.priceLabel}>
                            {props.job.fixedPrice
                                ? (props.job.fixedPrice * props.job.iva).toFixed(2) + " €"
                                : "0 €"}
                        </label>
                        <label style={{ fontSize: 13 }}>Precio cerrado</label>
                    </div>
                    :null
                }
            
                <div className="display-flex flex-column ">
                    
                {!props.biPro && <Button
                    style={{marginTop:"5px", height: 36, padding: 0, width: 110, backgroundColor: "#000000", borderColor: "#000000", color: "var(--coral)" }}
                    buttonText={props.buttonText || "Más info"}
                    onClick={props.onClick}
                />}
             
                  {user?.isGoldWoller  && user?.permissions?.job.edit && props.job.jobType === 'lead'  && props.job.state!=="finalizado"  && props.job.state!=="review" && props.job.totalSlots != props.job.nJober ? 
                             user?.isGoldWoller ? 
                             <Button
                                    className="stateButtonOnly"
                                    onClick={ShowIsStateModal}
                                    buttonText="Reportar"
                                    />
                             :wollers.map((woller)=>{
                                return currentSession?.wollerData?.id == woller?.data?.id && ( 
                                    <Button
                                    className="stateButtonOnly"
                                    onClick={ShowIsStateModal}
                                    buttonText="Reportar"
                                    key={"stateButton"+props.job.id}
                                    />
                                )
                             }):user?.permissions?.job.edit && props.job.jobType === 'lead'  && props.job.state!=="finalizado"  && props.job.state!=="review" && props.job.totalSlots != props.job.nJober &&(<Button
                                    className="stateButtonOnly"
                                    onClick={ShowIsStateModal}
                                    buttonText="Reportar"
                                    key={"stateButton"+props.job.id}
                                    />)
                              
                            }
                 

                    {!user?.isGoldWoller  && user?.permissions?.job.edit && props.job.jobType==="lead" && props.job.state==="finalizado" && 
                       
                          (  <Button
                                className="stateButton review"
                                onClick={ShowIsReviewModal}
                                buttonText={"Valorar"}
                            />)
                       
                    }
                    {user?.isGoldWoller && props.job.jobType==="lead" && props.job.state==="review"  ? 
                        wollers.map((woller)=>{
                        return currentSession?.wollerData?.id == woller?.id && ( 
                            <Button
                                className="stateButton  reviewed"
                                onClick={null}
                                buttonText={"valorado"}
                                  key={"reviewButton"+props.job.id}
                            />)
                        }):!user?.isGoldWoller && props.job.jobType==="lead" && props.job.state==="review" &&(
                            <Button
                                className="stateButton  reviewed"
                                onClick={null}
                                buttonText={"valorado"}
                                  key={"reviewButton"+props.job.id}
                            />)
                    }
                 
                </div>
            </div>
        </div>
    );
};

export default JobCell;
