import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { hasClassInTree } from "../../../utils/checkAncestorDOM";

import {MenuList} from 'jobin-ui-components'

export default function PaymentMethodCell(props) {
    const [optionsMenu, setOptionMenu] = useState(null);
    const [showOptions, setShowOptions] = useState(false);

    const style = {
        container: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            borderBottom: "1px solid var(--soft-grey)",
            paddingTop: 12,
            paddingBottom: 12
        },
        cardIcon:{
            height: 32,
            width: 32,
            padding: 4,
            marginRight: 12,
            borderRadius: 4,
            border: props.paymentMethod.get("Default") ? '2px solid var(--black-wolly)' : null
        },
        dateLabel: {
            textAlign: "right",
        },
        numberLabel: {
            display: 'flex',
            flex: 1,
            fontWeight: 700
        },
        nameLabel: {
            display: 'flex',
            flex: 1,
            fontSize: 12,
            lineHeight: '12px'
        },
        button: {
            border: 0,
            outline: 0,
            cursor: "pointer"
        },
        buttonImage: {
            height: 20,
            width: 20
        },
        dateView:{
            display: 'flex'
        },
        detailsView:{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            marginRight: 12,
        }
    };

    useEffect(() => {
        setOptionMenu(createOptionsMenu());

        const bodyClickHandler = e => {
            if (!hasClassInTree(e.srcElement, `filter custom options-no-hide`))
                setShowOptions(false);
        };

        document.body.addEventListener("click", bodyClickHandler, false);

        // unmount
        return () => {
            document.body.removeEventListener("click", bodyClickHandler, false);
        };
    }, []);

    const createOptionsMenu = () => {
        return [
            {
                id: "mark-as-default",
                title: "Marcar como predeterminado",
                disabled: props.paymentMethod.get("Default") ? true : false
            },
            {
                id: "delete",
                title: "Eliminar"
            }
        ];
    };

    const buttonItemSelected = option => {
        setShowOptions(false);
        props.buttonItemSelected(option);
    };

    return (
        <section style={style.container}>
            <img style={style.cardIcon} alt="paymentMethod" src={props.paymentMethod.get("Type") === "visa" ? require('../../../assets/card-visa.svg') : props.paymentMethod.get("Type") === "mastercard" ? require('../../../assets/card-mc.svg') : require('../../../assets/card.svg')}/>
            <div style={style.detailsView}>
                <label style={style.numberLabel}>{"**** **** **** **** "+props.paymentMethod.get("Last4")}</label>
                <label style={style.nameLabel}>{props.paymentMethod.get("Name")}</label>
            </div>
            <label style={style.dateLabel}>
                {"20/2024"}
            </label>
            <div className={`filter custom options-no-hide`}>
                <button
                    style={style.button}
                    onClick={() => setShowOptions(!showOptions)}
                >
                    <img
                        style={style.buttonImage}
                        src={require("../../../assets/option-dots.svg")}
                        alt="dots"
                    />
                </button>
                {showOptions && (
                    <MenuList
                        items={optionsMenu}
                        buttonItemSelected={buttonItemSelected}
                        left={true}
                    />
                )}
            </div>
        </section>
    );
}
