import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { useStateValue } from "../../state/context";
import { createOpsNotification, cancelJob } from "../../utils/ParserUtilities";


import { Input, Modal, CheckBox } from 'jobin-ui-components'

const CancelModal = ({isVisible, job, onClose, jobCanceled}) => {
    const style = {
        cashInputView: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            marginTop: 16,
            marginBottom: 16,
            fontSize: 40
        },
        checkbox: {
            border: 0,
            cursor: "pointer",
            outline: 0
        }
    };

    const [{user, agent}] = useStateValue();
    const [loading, setLoading] = useState(false);
    const [paymentSummary, setPaymentSummary] = useState(null);
    const [cancelPaymentSummary, setCancelPaymentSummary] = useState(null);
    const [refoundAmount, setRefoundAmount] = useState(0.0);
    const [refoundTpvAmount, setRefoundTpvAmount] = useState(null);
    const [refoundPaycardAmount, setRefoundPaycardAmount] = useState(null);
    const [refoundCashAmount, setRefoundCashAmount] = useState(null);
    const [refoundTransferAmount, setRefoundTransferAmount] = useState(null);
    const [showFalse, setShowFalse] = useState(false);
    const [showIban, setShowIban] = useState(false);
    const [iban, setIban] = useState(null);
    const [ibanOk, setIbanOk] = useState(true);
    const [measurePrice, setMeasurePrice] = useState(null);
    const [textAnulationReason, setTextAnulationReason] = useState("");


    const [title, setTitle] = useState(!user?.isGoldWoller && user?.isConstructionCompany && agent?.dept != "ConstructionCompany" ? "Anulación del servicio" :agent?.dept == "ConstructionCompany" ? "Rechazo del servicio": "Cancelación del servicio");
    const [subtitle, setSubtitle] = useState(
        job.get("JobType") === "lead"
            ? "¿Está seguro que quiere anular/cancelar el servicio? Este proceso no tiene vuelta atrás."
            :agent.dept == "ConstructionCompany" ? "¿Está seguro que quiere rechazar el servicio? Este proceso no tiene vuelta atrás.": "Se va a cancelar este servicio. Antes realiza las devoluciones al cliente."
    );

    const [measureSelected, setMeasureSelected] = useState(
        true //job.get("JobType") === "transport"
    );

    useEffect(() => {
        //Get all the user payments
        async function getPayments() {
            setLoading(true);
            const result = await Jobin.Job.getPaymentsSummaryForCancelation(
                job
            );
            if (result.status === "ok") {
                setPaymentSummary(result.result.returnAll);
                setCancelPaymentSummary(result.result.keepMeasures);
                let quantities = measureSelected
                    ? result.result.returnAll
                    : result.result.keepMeasures;
                setRefoundTpvAmount(quantities.tpv);
                setRefoundPaycardAmount(quantities.paycard);
                setRefoundCashAmount(quantities.cash);
                setRefoundTransferAmount(quantities.transfer);
                const refoundPrice =
                    quantities.tpv +
                    quantities.cash +
                    quantities.transfer +
                    quantities.paycard;
                setRefoundAmount(refoundPrice);

                //Get measure price
                if (job.get("JobType") === "fixed") {
                    setMeasurePrice(result.result.measuresPrice);
                }
            } else {
                alert(result.result);
                onClose();
            }


            setLoading(false);
        }
        if (
            job.get("JobType") === "fixed" ||
            job.get("JobType") === "transport"
        )
            getPayments();
    }, []);

   


    const cancelAction = async action => {
        setLoading(true);
        const result = await cancelJob({
            jobId:job.id,
            action:action,
            summary:measureSelected ? paymentSummary : cancelPaymentSummary,
            iban:iban,
            sessionToken:agent.token,
            comments:textAnulationReason}
        );
        if ( textAnulationReason ) {
            await createOpsNotification(job.id, textAnulationReason, "Motivo anulación");
        }
        
        if (result.status === "ok") {
            setLoading(false);
            jobCanceled(action);
        } else {
            setLoading(false);
            alert(result.result);
        }
    };

    const addIBAN = input => {
        if (input.length > 0) {
            var reg = /^\s*ES\s*\d{2}\s*\d{4}\s*\d{4}\s*\d{2}\s*\d{2}\s*\d{4}\s*\d{4}\s*$/;
            if (reg.test(input)) {
                if (input.length > 0) {
                    setIban(input);
                    setIbanOk(true);
                } else {
                    setIban(null);
                    setIbanOk(false);
                }
            } else {
                setIban(null);
                setIbanOk(false);
            }
        } else {
            setIban(null);
            setIbanOk(true);
        }
    };

    const renderIBAN = () => {
        return (
            <div style={style.cashInputView}>
                <Input
                    style={{ display: "flex", flex: 1 }}
                    placeholder="IBAN del cliente"
                    autoFocus
                    onChange={i => addIBAN(i.target.value)}
                    badInput={!ibanOk}
                />
            </div>
        );
    };

    useEffect(() => {
        let quantities = measureSelected
            ? paymentSummary
            : cancelPaymentSummary;
        if (quantities) {
            setRefoundTpvAmount(quantities.tpv);
            setRefoundPaycardAmount(quantities.paycard);
            setRefoundCashAmount(quantities.cash);
            setRefoundTransferAmount(quantities.transfer);
            const refoundPrice =
                quantities.tpv +
                quantities.cash +
                quantities.transfer +
                quantities.paycard;
            setRefoundAmount(refoundPrice);
        }
    }, [measureSelected]);

    const onClick = () =>{
        if(showFalse){
            cancelAction("eliminado");
        }
        else if(showIban){
            setTitle("¿Es un servicio falso?");
            setSubtitle(
                "Necesitamos saber si es un servicio falso o si ha sido una cancelación por parte del cliente."
            );
            setShowFalse(true);
        }
        else{
            if (refoundTransferAmount && refoundTransferAmount.toFixed(2) > 0) {
                setTitle("IBAN del cliente");
                setSubtitle(
                    "Añade el IBAN de la cuenta del cliente a donde se devolverá el dinero."
                );
                setShowIban(true);
            } 
            else {
                setTitle("¿Es un servicio falso?");
                setSubtitle(
                    "Necesitamos saber si es un servicio falso o si ha sido una cancelación por parte del cliente."
                );
                setShowFalse(true);
            }
        }
    }

    const goBack = () =>{
        setShowIban(false);
        setShowFalse(false);
        setTitle("Cancelación del servicio");
        setSubtitle(
            job.get("JobType") === "lead"
                ? "¿Seguro? Este proceso no tiene vuelta atrás."
                : "Se va a cancelar este servicio. Antes realiza las devoluciones al cliente."
        );
    }

    const handleChangeDescription = async (event) => {
        setTextAnulationReason(event.target.value);
    }

    return (
        <Modal
            title={title}
            subtitle={subtitle}
            onClose={onClose}
            buttonText={showFalse ? "Cancelación" : (showIban ? "Siguiente" : (!user?.isGoldWoller && user?.isConstructionCompany && agent?.dept != "ConstructionCompany"  ? "Anular servicio":agent?.dept == "ConstructionCompany" ? "Rechazar servicio": "Cancelar servicio"))}
            secondButtonText={showFalse ? "Falso" : null}
            loading={loading}
            open={isVisible}
            onClick={agent?.dept != "ConstructionCompany" ? onClick:() => cancelAction("eliminado") }
            secondOnClick={() => cancelAction("falso")}
            goBack={(showIban || showFalse) ? goBack : null}
        >
            {(user?.isConstructionCompany || job.get("Business")?.id == "M7t1A74luq") && (
                <div>
                    <label style={{color: '#B0B0B0', fontSize: '12px', marginTop: '8px'}}>{agent.dept == "ConstructionCompany" ? "Motivo del rechazo":"Motivo de la anulación"}</label>
                        <textarea
                        style={{
                            width: '100%',
                            padding: '5px',
                            border: '1px solid #E3E3E3',
                            borderRadius: '4px',
                            minHeight: '80px',
                            resize: 'none'
                        }}
                        value={textAnulationReason}
                        onChange={handleChangeDescription}
                    ></textarea>

                </div>
            )}

            {showFalse || agent.dept == "ConstructionCompany"?  (
                null
            ) : showIban ? (
                renderIBAN()
            ) : (
                <section style={{marginBottom: 24}}>
                    {job.get("State") !== "draft" && measurePrice && (
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                alignItems: "center",
                                marginTop: 16
                            }}
                        >
                            <CheckBox
                                title={"Devolver " + (measurePrice * 1.21).toFixed(2) + " € de mediciones"}
                                onChange={() =>
                                    setMeasureSelected(
                                        measureSelected => !measureSelected
                                    )
                                }
                                selected={measureSelected}
                            />
                        </div>
                    )}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: 16
                        }}
                    >
                        {refoundAmount > 0 && (
                            <label
                                style={{ fontWeight: "bold", marginBottom: 8 }}
                            >
                                {"Total a devolver: " +
                                    (refoundAmount * 1.21).toFixed(2) +
                                    " €"}
                            </label>
                        )}
                        {refoundCashAmount > 0 && (
                            <label>
                                {"Devolución en efectivo: " +
                                    (refoundCashAmount * 1.21).toFixed(2) +
                                    " €"}
                            </label>
                        )}
                        {refoundTpvAmount > 0 && (
                            <label>
                                {"Devolución por tarjeta (TPV): " +
                                    (refoundTpvAmount * 1.21).toFixed(2) +
                                    " €"}
                            </label>
                        )}
                        {refoundPaycardAmount > 0 && (
                            <label>
                                {"Devolución automática por Stripe: " +
                                    (refoundPaycardAmount * 1.21).toFixed(2) +
                                    " €"}
                            </label>
                        )}
                        {refoundTransferAmount > 0 && (
                            <label>
                                {"Devolución por transferencia: " +
                                    (refoundTransferAmount * 1.21).toFixed(2) +
                                    " €"}
                            </label>
                        )}
                    </div>
                </section>
            )}
        </Modal>
    );
};

export default CancelModal;
