import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';

import JobsMapsMarker from "./JobsMapsMarker";
import JobsMapsCard from "./JobsMapsCard";
import AutoComplete from "./AutoComplete";
import {getFilteredJobsMap, getWollersByVerticalsForMap, getJobsType, buyJobForWoller, modifyJobLeadPrice, getJobById,editOpsNotification, getCRMUserTokenById} from "../../utils/ParserUtilities";
import JobsMapsFilterModal from "../modal/JobsMapsFilterModal";
import JobsMapsWollerMarker from './JobsMapsWollerMarker';
import { useStateValue } from "../../state/context";
import ServiceDetails  from "../../components/modal/ServiceDetails.jsx";
import AlertModal from "../modal/Alert";



import "../../styles/JobsMaps/jobs-maps.css";
import JobsMapsWollerCard from './JobsMapsWollerCard';

const JobsMaps = (props) => {
    const [{ user,currentSession, agent }] = useStateValue();

    const [center, setCenter] = useState({
        lat: 40,
        lng: -3.5
    });
    const [zoom, setZoom] = useState(6.4);
    const [jobs, setJobs] = useState([]);
    const [wollers, setWollers] = useState([]);
    const [jobCard, setJobCard] = useState(undefined);
    const [wollerCard, setWollerCard] = useState(undefined);
    const [showWollerCard, setShowWollerCard] = useState(false);
    const [map, setMap] = useState([]);
    const [googlemaps, setGooglemaps] = useState(null);
    const [mapApiLoaded, setMapApiLoaded] = useState(false);
    const [showJobsMapsFilterModal, setShowJobsMapsFilterModal] = useState(false);
    const [matches, setMatches] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showjobDetails, setShowjobDetails] = useState(false);


    //Notifications
    const [numberCheckNotifications, setNumberCheckNotifications] = useState(0);
    const [numberUrgentNotifications, setNumberUrgentNotifications] = useState(0);
    const [numberNoDataNotifications, setNumberNoDataNotifications] = useState(0);
    const [numberLocationNotifications, setNumberLocationNotifications] = useState(0);
    
    //Filtros
    const [selectedVerticals, setSelectedVerticals] = useState([]);
    const [selectedProvinces, setSelectedProvinces] = useState([]);
    const [selectedBusiness, setSelectedBusiness] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState(undefined);
    const [selectedTotalSlots, setSelectedTotalSlots] = useState([])
    const [selectedEmpty, setSelectedEmpty] = useState([])
    const [selectedShowWollers, setSelectedShowWollers] = useState([])

    
    const [dateRange, setDateRange] = useState([null, null]);

    useEffect(()=> {
        // let endDate = new Date();
		// endDate.setDate(endDate.getDate() - 7);
        // setDateRange([endDate, new Date()]);

        if(window.innerWidth <= 600){
            setZoom(5.75)
        }

        if(currentSession && currentSession.wollerData && currentSession.wollerData.id ){
            getWollerVerticals();
        } else {
            getAllJobs();
        }
    },[]);

    useEffect(()=> {
        if(selectedShowWollers.value == "No") setWollers([]);
        if(selectedVerticals.length >= 1 && selectedShowWollers.value == "Si"){
            getAllWollers();
        } else if(selectedVerticals.length == 0){
            setWollers([]);
        }

        if(currentSession && currentSession.wollerData && currentSession.wollerData.id && selectedVerticals.length > 0){
            getAllJobs();
        }
    },[selectedVerticals, selectedShowWollers]);

    const getAllWollers = async () => {
        setWollers([]);
        let res = await getWollersByVerticalsForMap(selectedVerticals);

        const uniqueArray = res.filter((value, index) => {
            const _value = JSON.stringify(value);
            return index === res.findIndex(obj => {
              return JSON.stringify(obj) === _value;
            });
          });

        setWollers(uniqueArray);
    }

    const getWollerVerticals = async () => {
        let res = await getJobsType(currentSession.wollerData.id);
        const newSelectedVerticals = [];

        res.map( vertical => {
            if(vertical.get("Vertical")){
                newSelectedVerticals.push({value: vertical.get("Vertical").id, label: vertical.get("Type")})
            }
        })

        setSelectedVerticals(newSelectedVerticals);
    }

    const getAllJobs = async () => {
        setShowjobDetails(false)
        setJobs([]); 
        setJobCard(undefined);

        let provinces = []
        if(selectedProvinces.length > 0){
           selectedProvinces.map( prov => {
               provinces.push(prov.value)
           })
        }

        let res = await getFilteredJobsMap(selectedVerticals, provinces, selectedBusiness, selectedAgents,
            dateRange, selectedTotalSlots);
                
        if(selectedEmpty.value == "Yes"){
            res = res.filter( job => {
                if(job.get("TotalSlots") == job.get("nJober")) return true;
                else return false;
            })
        }

        setJobs(res);
        countNumberNotifications(res)
    }

    const countNumberNotifications = (res) => {
        let check = 0
        let urgent = 0
        let noData = 0
        let location = 0

        res.map( job => {
            if(job.get("OpsNotification")){
                const typologies = job.get("OpsNotification").get("ExtraInfo").typologies;
                if(typologies.length > 0){
                    if(typologies.includes("Cliente confirma diagnóstico")) check++;
                    else if(typologies.includes("Ilocalizable")) location++;
                    else if(typologies.includes("Urgente")) urgent++;
                    else if(typologies.includes("Falta información")) noData++;
                }
            }
        })

        setNumberCheckNotifications(check)
        setNumberUrgentNotifications(urgent)
        setNumberNoDataNotifications(noData)
        setNumberLocationNotifications(location)
    }

    const handleApiLoaded = (map, maps) => {
        if (map && maps) {
            setMap(map);
            setGooglemaps(maps);    
            setMapApiLoaded(true);      
        }
    };
    const parseDataForDetails = (job) => {
        
        setJobCard(job)
        setShowjobDetails(true)
    };
    const asignJober = async jober => {
        setJobCard(false);
  

        let newToken = await getCRMUserTokenById(agent.agentId);

        if (currentSession?.wollerData?.id){
            await buyJobForWoller(jobCard.id, currentSession?.wollerData?.id, newToken.get("sessionToken"));
            setShowConfirmModal(false);
            getAllJobs();

        } else{
            await buyJobForWoller(jobCard.id, jober.id, newToken.get("sessionToken"));
            getAllJobs();
        }
    };

    return (
        <div className='jobs-maps-container'>
            { showConfirmModal && (
                <AlertModal
                    isVisible={showConfirmModal}
                    onClose={() => {
                        setShowConfirmModal(false);
                    }}
                    title={jobCard.title}
                    subtitle="¿Seguro que deseas comprar este trabajo?"
                    buttonText="Comprar"
                    onClick = {() => asignJober() }
                />
            )}
            {!currentSession.wollerData && showjobDetails && <JobsMapsCard 
                        job={jobCard}
                        getAllJobs={() => getAllJobs()}
                        setJobCard={() => setShowjobDetails(false)}
                        matches={matches}
                        setMatches={setMatches}
                        history={props.history}
                        jobs={jobs}
                        setJobs={setJobs}
                    />
                }
            {currentSession.wollerData && showjobDetails && <ServiceDetails
                isVisible={showjobDetails}
                service={{
                    totalSlots:jobCard.get("TotalSlots"),
                    takenSlots:jobCard.get("TotalSlots") - jobCard.get("nJober"),
                    jobType:jobCard.get("JobType"),
                    level: jobCard.get("Level"),
                    price: jobCard.get("JobLeadPrice"),
                    jobLeadPrice: jobCard.get("JobLeadPrice"),
                    title: jobCard.get("Title"),
                    id: jobCard.id,
                    vertical: jobCard.get("Vertical"),
                    type: jobCard.get("Type"),
                    createdBy: jobCard.get("CreatedBy"),
                    address: jobCard.get("Address"),
                    description: jobCard.get("Description"),
                    business:jobCard.get("Business")
                }}
                jobCard={jobCard}
                handleBuyFromModal={asignJober}
                forOwn={true}
                onClose={()=>{setShowjobDetails(false)}}
            />
            
            }
            {
                showWollerCard && (
                    <JobsMapsWollerCard
                        woller={wollerCard}
                        setShowWollerCard={() => setShowWollerCard(undefined)}
                    />
                )
            }

            <div className='jobs-maps-header'>
                {mapApiLoaded && (
                    <AutoComplete map={map} mapApi={googlemaps} />
                )}
                <button className='jobs-maps-filter-button' onClick={() => setShowJobsMapsFilterModal(true)}> 
                    <img className="jobs-maps-icon" src={require("../../assets/settingsIcon.png")} /> 
                    Filtros
                </button>   
                {
                    showJobsMapsFilterModal && (
                        <JobsMapsFilterModal
                            isVisible={true}
                            onClose={() => setShowJobsMapsFilterModal(false)}
                            selectedVerticals={selectedVerticals}
                            setSelectedVerticals={setSelectedVerticals}
                            getAllJobs={getAllJobs}
                            selectedProvinces={selectedProvinces}
                            setSelectedProvinces={setSelectedProvinces}
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                            selectedBusiness={selectedBusiness}
                            setSelectedBusiness={setSelectedBusiness}
                            selectedTotalSlots={selectedTotalSlots}
                            setSelectedTotalSlots={setSelectedTotalSlots}
                            selectedEmpty={selectedEmpty}
                            setSelectedEmpty={setSelectedEmpty}
                            selectedAgents={selectedAgents}
                            setSelectedAgents={setSelectedAgents}
                            selectedShowWollers={selectedShowWollers}
                            setSelectedShowWollers={setSelectedShowWollers}
                        />
                    )
                }
            </div> 
            
            <div className='jobs-maps-map'>
                <button className='jobs-maps-filter-button-responsive' onClick={() => setShowJobsMapsFilterModal(true)}> 
                    <img className="jobs-maps-icon" src={require("../../assets/settingsIcon.png")} /> 
                    Filtros
                </button>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyDYwng9doYyVkIIyzYLX6crFuJLii07Cus" }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                >
                {
                    jobs.map( (job, i) => {
                        return <JobsMapsMarker
                                    key={i}
                                    lat={job.get("Location")._latitude}
                                    lng={job.get("Location")._longitude}
                                    job={job}
                                    setJobCard={() => parseDataForDetails(job)}
                                    matches={matches}
                                    setMatches={setMatches}
                                />
                    })
                }
                {!currentSession?.wollerData?.id && (
                    wollers.map( (woller, i) => {
                        if(woller.get("Location"))
                            return <JobsMapsWollerMarker
                                        key={i}
                                        lat={woller.get("Location")._latitude}
                                        lng={woller.get("Location")._longitude}
                                        woller={woller}
                                        setShowWollerCard={setShowWollerCard}
                                        setWollerCard={setWollerCard}
                                    />
                    })
                )}
                </GoogleMapReact>
            </div>

            <div className='jobs-maps-footer'>
                <p className='jobs-maps-footer-open'>Existen {jobs.length} trabajos abiertos en base a los filtros.</p>
            
                    {user.isGoldWoller ? null:
                        <>
                            <img className='jobs-maps-footer-icon' src={require("../../assets/notifications/check.png")}/>
                            <p className='jobs-maps-footer-p'>{numberCheckNotifications}</p>

                            <img className='jobs-maps-footer-icon' src={require("../../assets/notifications/ray.png")}/>
                            <p className='jobs-maps-footer-p'>{numberUrgentNotifications}</p>

                            <img className='jobs-maps-footer-icon' src={require("../../assets/notifications/location.png")}/>
                            <p className='jobs-maps-footer-p'>{numberLocationNotifications}</p>

                            <img className='jobs-maps-footer-icon' src={require("../../assets/notifications/no_data.png")}/>
                            <p className='jobs-maps-footer-p'>{numberNoDataNotifications}</p>
                        </>
                    }
            </div>
        </div>
    );
}

export default JobsMaps;