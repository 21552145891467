import React from "react";
import { useStateValue } from "../../state/context";
import ".././../styles/singleComponents/paginator.css"


export default function ResultCount({findFunction,controllerState,reducerFunction}) {
    const [{ currentSession },dispatch] = useStateValue();


    const handlePag=(action)=>{
        let page
        if ( action === "back"){
            page = controllerState.currentPage - 1 > 0 ? controllerState.currentPage - 1: 1
        }else if (action === "next"){
            page =  controllerState.currentPage + 1 < Math.ceil(controllerState.count/controllerState.limit) ? controllerState.currentPage + 1: Math.ceil(controllerState.count/controllerState.limit)
        }
        dispatch({
            type: reducerFunction,
            currentPage:  page
        })
        findFunction({page:page})
        
    }
    if(!currentSession.allowedFilters?.mediator){
        return (
            <div className="paginator container">
                <button disabled={controllerState.currentPage === 1 } className="paginationButton back" onClick={()=>handlePag("back")}>◄</button>
                <label>
                    {` Mostrando ${controllerState.services.length} resultados de ${controllerState.count}.  pagina ${controllerState.currentPage} de  ${Math.ceil(controllerState.count/controllerState.limit)} `}
                </label>
                <button disabled={Math.ceil(controllerState.count/controllerState.limit) === controllerState.currentPage} className="paginationButton next" onClick={()=>handlePag("next")}>►</button>
            </div>
        );
    }else{
        return null
    }
}
