import React, { useState, useEffect } from "react";
import { useStateValue } from "../../../state/context";

import { CountDown } from "jobin-ui-components";

export default function JobStatus(props) {
    const [{ jobDetails }] = useStateValue();
    const [statesArray, setStatesArray] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [endDate, setEndDate] = useState(null);

    const getStatesArray = jobType => {
        if (jobType === "fixed") {
            return [
                "Publicado",
                "Cita",
                "Profesional",
                "Pend. firma",
                "Firmado",
                "Valorado"
            ];
        } else if (jobType === "transport") {
            return [
                "Publicado",
                "Fecha entrega",
                "A reparto",
                "Entregado",
                "Firmado",
                "Valorado"
            ];
        } else {
            return ["Publicado", "Jobers asignados", "Finalizado", "Valorado"];
        }
    };

    const getSelectedIndex = (job) => {
        if (job.get("JobType") === "lead") {
            if (job.get("State") === "review") return 4;
            else if (job.get("State") === "finalizado") return 3;
            else if (job.get("TotalSlots") - job.get("nJober") > 0) return 2;
            else if (job.get("State") === "abierto") return 1;
            else return 0;
        } else {
            if (job.get("State") === "review") return 6;
            else if (job.get("State") === "finalizado") return 5;
            else if (job.get("State") === "signaturePending") return 4;
            else if (job.get("State") === "full") return 3;
            else if (job.get("State") === "abierto") return 2;
            if (job.get("State") === "pending") return 1;
            else return 0;
        }
    };

    const getEndDate = (jobType, jobState, publishDate, appointment, priority) => {
        if (jobType !== "fixed" || !publishDate) return null;
        else {
            let date = publishDate && new Date(publishDate.getTime());
            if (jobState === "full" && appointment)
                return date.setDate(date.getDate() + 7);
            else if (jobState === "full" || jobState === "abierto")
                return priority === "urgent" ? date.setHours(date.getHours() + 3) : date.setHours(date.getHours() + 48);
            if (jobState === "pending")
                return priority === "urgent" ? date.setHours(date.getHours() + 1) : date.setHours(date.getHours() + 24);
            else return null;
        }
    };

    useEffect(() => {
        setStatesArray(getStatesArray(jobDetails.job.get("JobType")));
        setSelectedIndex(getSelectedIndex(jobDetails.job)
        );
        setEndDate(
            getEndDate(
                jobDetails.job.get("JobType"),
                jobDetails.job.get("State"),
                jobDetails.fixedInfo && jobDetails.fixedInfo.get("PublishDate"),
                jobDetails.fixedInfo && jobDetails.fixedInfo.get("Appointment"),
                jobDetails.job.get("Priority")
            )
        );
    }, [
        jobDetails.job.get("updatedAt"),
        jobDetails.fixedInfo && jobDetails.fixedInfo.get("updatedAt"),
        jobDetails.transportInfo && jobDetails.transportInfo.get("updatedAt")
    ]);

    const styles = {
        container: {
            position: "absolute",
            display: "flex",
            flex: 1,
            bottom: 0,
            right: 0,
            left: 64,
            textAlign: "center",
            borderTop: "1px solid var(--soft-grey)",
            boxShadow: "0 -2px 4px 0 rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
            alignItems: "center",
            ...props.style
        },
        section: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            paddingRight: 32,
            paddingLeft: 32,
            marginTop: 24,
            marginBottom: 12
        },
        counterSection: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: 24,
            color: "!important #ff3569"
        },
        stepContainer: {
            display: "flex",
            flex: 1,
            alignItems: "center"
        },
        line: {
            display: "flex",
            flex: 1,
            height: 3,
            backgroundColor: "var(--light-grey)",
            marginBottom: 24
        },
        lineSelected: {
            display: "flex",
            flex: 1,
            height: 3,
            backgroundColor: "var(--black-wolly-darker)",
            marginBottom: 24
        },
        pseudoLine: {
            position: "absolute",
            top: 4,
            width: "100%",
            height: 3,
            backgroundColor: "var(--light-grey)"
        },
        pseudoLineSelected: {
            position: "absolute",
            top: 4,
            width: "100%",
            height: 3,
            backgroundColor: "var(--black-wolly-darker)"
        },
        pseudoLineFirst: {
            position: "absolute",
            top: 4,
            right: 0,
            width: "50%",
            height: 3,
            backgroundColor: "var(--light-grey)"
        },
        pseudoLineLast: {
            position: "absolute",
            top: 4,
            left: 0,
            width: "50%",
            height: 3,
            backgroundColor: "var(--light-grey)"
        },
        pseudoLineFirstSelected: {
            position: "absolute",
            top: 4,
            right: 0,
            width: "50%",
            height: 3,
            backgroundColor: "var(--black-wolly-darker)"
        },
        pseudoLineLastSelected: {
            position: "absolute",
            top: 4,
            left: 0,
            width: "50%",
            height: 3,
            backgroundColor: "var(--black-wolly-darker)"
        },
        dotContainer: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        dot: {
            height: 12,
            width: 12,
            marginBottom: 4,
            borderRadius: 6,
            backgroundColor: "var(--light-grey)"
        },
        dotSelected: {
            height: 12,
            width: 12,
            marginBottom: 4,
            borderRadius: 6,
            backgroundColor: "var(--black-wolly-darker)"
        }
    };

    return (
        <section style={styles.container}>
            <div style={styles.section}>
                {statesArray.map((item, index) => {
                    if (index + 1 !== statesArray.length) {
                        return (
                            <div
                                style={styles.stepContainer}
                                key={"status" + index}
                            >
                                <div style={styles.dotContainer}>
                                    <div
                                        style={
                                            index < selectedIndex
                                                ? index === 0
                                                    ? styles.pseudoLineFirstSelected
                                                    : styles.pseudoLineSelected
                                                : index === 0
                                                ? styles.pseudoLineFirst
                                                : styles.pseudoLine
                                        }
                                    />
                                    <div
                                        style={
                                            index < selectedIndex
                                                ? styles.dotSelected
                                                : styles.dot
                                        }
                                    />
                                    <label
                                        style={{
                                            fontSize: 12,
                                            color:
                                                index < selectedIndex + 1
                                                    ? "var(--greyish-brown)"
                                                    : "var(--greyish)"
                                        }}
                                    >
                                        {item}
                                    </label>
                                </div>
                                <div
                                    style={
                                        index < selectedIndex
                                            ? styles.lineSelected
                                            : styles.line
                                    }
                                />
                            </div>
                        );
                    } else {
                        return (
                            <div
                                style={styles.stepContainer}
                                key={"statusfinal"}
                            >
                                <div style={styles.dotContainer}>
                                    <div
                                        style={
                                            index < selectedIndex
                                                ? styles.pseudoLineLastSelected
                                                : styles.pseudoLineLast
                                        }
                                    />
                                    <div
                                        style={
                                            index + 1 === selectedIndex
                                                ? styles.dotSelected
                                                : styles.dot
                                        }
                                    />
                                    <label
                                        style={{
                                            fontSize: 12,
                                            color:
                                                selectedIndex >= index
                                                    ? "var(--greyish-brown)"
                                                    : "var(--greyish)"
                                        }}
                                    >
                                        {item}
                                    </label>
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
            <div style={styles.counterSection}>
                {endDate && <CountDown  endDate={endDate} />}
            </div>
        </section>
    );
}
