import React, { useState ,useEffect} from "react";
import Jobin from "jobin-client";
import {Loading} from 'jobin-ui-components'
import { useStateValue } from "../../../../state/context";

import PaymentCell from "./PaymentCell";
import Alert from "../../../modal/Alert";
import PaymentMethodModal from "../../../modal/PaymentMethodModal";
import FiscalModal from "../../../modal/FiscalModal";
import EditFixedPriceModal from "../../../modal/EditFixedPriceModal";
import PostPayModal from "../../../modal/PostPayModal";
import IvaModal from "../../../modal/IvaModal.jsx";
import {sendBudgetAproval,sendRegetBudgetContact, updateFixedProperty} from "../../../../../src/utils/ParserUtilities"

import "../../../../styles/search/detail/job-detail-info.css";

export default function PaymentsContainer({fetchData}) {
    const [{user,jobDetails,general,agent}, dispatch] = useStateValue();

    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertBudget, setShowAlertBudget] = useState(false);
    const [showAlertBudgetPropousal, setShowAlertBudgetPropousal] = useState(false);
    const [showFiscalModal, setShowFiscalModal] = useState(false);
    const [showPaymentMethod, setShowPaymentMethod] = useState(false);
    const [showEditFixedPrice, setShowEditFixedPrice] = useState(false);
    const [showPostPay, setShowPostPay] = useState(false);
    const [showIvaModal, setShowIvaModal] = useState(false);
    const [payment, setPayment] = useState(null);
    const [currentPaymentState,setCurrentPaymentState] =  useState(null);
    const style = {
        total: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            textAlign: "right",
            marginTop: 16
        },
        totalPriceLabel:{
            fontSize: 16,
            fontWeight: "bold"
        },
        priceLabel:{
            fontSize: 12,
        }
    };

    useEffect(()=>{
        paymentState()
    },[])

    const paymentOptionSelected = (payment, option) => {
        setPayment(payment);
        switch (option.id) {
            case "modify-payment-method":
                setShowPaymentMethod(true);

                break;
            case "mark-as-paid":
                setShowAlert(true);
                
                break;
            case "receipt":
                const win = window.open(payment.get("Receipt").url(), "_blank");
                win.focus();
                    
                break;
            case "modify-receipt":
                setShowFiscalModal(true);

                break;
            case "modify-IVA":
                setShowIvaModal(true)
                break;
            case "edit-price":
                setShowEditFixedPrice(true)
                break;
            case "run-posPay":
                setShowPostPay(true)
                break;
            case "budget-propousal":
                setShowAlertBudgetPropousal(true)
                break;
            case "budget-accepted":
                setShowAlertBudget(true)
                break;
        
            default:
                break;
        }

    };
    const paymentState = ()=>{
        var state 
        jobDetails.paymentsArray.forEach(element => {
            const action = element.get("Action");
            const paidDay = element.get("PaidDay");
            if(!action && paidDay ){
                state = "Done";
            }else{
                state = action;
            }
        });
        setCurrentPaymentState(state)
    }
    const sendAcceptBudget = async ()=>{
        let result = await sendBudgetAproval({
            agentId:agent?.id,
            jobId:jobDetails?.job?.id,
            price:jobDetails?.job?.get("PriceMax"),
            externalId:jobDetails?.job?.get("externalId"),
            tax:Math.trunc((jobDetails?.fixedInfo?.get("IVA") * 100)-100),
            businessId:jobDetails?.job?.get("Business")?.id
        })
        if(!result){
            alert("Algo ha fallado al enviar la notificacion")
        }
        jobDetails.fetchData()
    }
    const sendBudgetPropousal = async ()=>{
        let result = await sendRegetBudgetContact({
            agentId:agent?.id,
            jobId:jobDetails?.job?.id,
            price:jobDetails?.job?.get("PriceMax"),
            externalId:jobDetails?.job?.get("externalId"),
            tax:Math.trunc((jobDetails?.fixedInfo?.get("IVA") * 100)-100),
            businessId:jobDetails?.job?.get("Business")?.id
        })
        if(!result){
            alert("Algo ha fallado al enviar la notificacion")
        }
        jobDetails.fetchData()
    }

    const markPaymentAsPaid = async () => {
        setLoading(true);
        const result = await Jobin.Payment.markAsPaid(payment);
        if (result.status === "ok") {
            //Update the current payments array
            const tempArray = jobDetails.paymentsArray;
            for (let i = 0; i < tempArray.length; i++) {
                if (tempArray[i].id === payment.id) {
                    tempArray[i].set("Action", undefined);
                    break;
                }
            }
            await jobDetails.getHistoryOfJob(jobDetails.job);
            dispatch({
                type: "JOB_DETAILS_JOB",
                paymentsArray: tempArray
            });
        } else {
            alert(result.result);
        }
        setLoading(false);
    };

    const changePaymentMethod = async paymentMethod => {
        setLoading(true);
        const result = await Jobin.Payment.changePaymentMethod(
            payment,
            paymentMethod
        );
        if (result.status === "ok") {
            await jobDetails.getPayments(jobDetails.job);
            await jobDetails.getHistoryOfJob(jobDetails.job);
        } else {
            alert(result.result);
        }
        setLoading(false);
    };

    const remakeBillSuccess = async (billingInfo) =>{
        setLoading(true);
        setShowFiscalModal(false);
        const result = await Jobin.PDF.remakeBill(payment, billingInfo);
        if(result.status === 'ok'){
            setLoading(false);
            var win = window.open(result.result, '_blank');
            win.focus();
        }
        else{
            setLoading(false);
            alert(result.result);
        }
    }
    const onSaveNewIva = async (ivaSelected) =>{
        setShowIvaModal(false);
        let result = await updateFixedProperty({id:jobDetails.job.id,property:"IVA",value:ivaSelected})
        if(!result){
            console.error(result)
            alert("Algo ha fallado al actualizar el iva")
            setLoading(false);
        }
        jobDetails.fetchData()
    }

    return (
        <section className="infoContainer">
            {showAlert && (
                <Alert
                    isVisible={showAlert}
                    onClose={() => setShowAlert(false)}
                    title={"¿Deseas continuar?"}
                    subtitle={
                        "Si marcas este pago como pagado significa que se ha realizado con éxito en el mismo método de pago que el indicado. Si no es así, cancela el proceso y modifica el método de pago."
                    }
                    buttonText={"Marcar como pagado"}
                    onClick={() => {
                        setShowAlert(false);
                        markPaymentAsPaid();
                    }}
                />
            )}
            {showAlertBudgetPropousal && (
                <Alert
                    isVisible={showAlertBudgetPropousal}
                    onClose={() => setShowAlertBudgetPropousal(false)}
                    title={"¿Deseas enviar este presupuesto al cliente?"}
                    subtitle={
                    `El presupuesto del servicio tiene un importe de ${(jobDetails.job.get("PriceMax")).toFixed(2)} € con un porcentaje de IVA del ${Math.trunc((jobDetails?.fixedInfo?.get("IVA") * 100)-100)}%. Asegúrate de que todo es correcto antes de enviar la notificación. Si no es así, cancela el proceso y modifica el importe o el IVA.`
                    }
                    buttonText={"Enviar notificación al cliente"}
                    onClick={() => {
                        setShowAlertBudgetPropousal(false);
                        sendBudgetPropousal();
                    }}
                />
            )}
            {showAlertBudget && (
                <Alert
                    isVisible={showAlertBudget}
                    onClose={() => setShowAlertBudget(false)}
                    title={"¿Deseas continuar?"}
                    subtitle={
                    `El importe del servicio es ${(jobDetails.job.get("PriceMax")).toFixed(2)} € con un porcentaje de IVA del ${Math.trunc((jobDetails?.fixedInfo?.get("IVA") * 100)-100)}%. Asegúrate de que todo es correcto antes de enviar la notificación. Si no es así, cancela el proceso y modifica el importe o el IVA.`
                    }
                    buttonText={"Enviar notificación"}
                    onClick={() => {
                        setShowAlertBudget(false);
                        sendAcceptBudget();
                    }}
                />
            )}
            {showPaymentMethod && (
                <PaymentMethodModal
                    isVisible={showPaymentMethod}
                    onClose={() => setShowPaymentMethod(false)}
                    job={jobDetails.job}
                    initialView={"change"}
                    savePaymentMethod={paymentMethod => {
                        setShowPaymentMethod(false);
                        changePaymentMethod(paymentMethod);
                    }}
                />
            )}
            {showFiscalModal && 
                <FiscalModal
                    isVisible={showFiscalModal}
                    onClose={() => setShowFiscalModal(false)}
                    user={jobDetails.job.get("CreatedBy")}
                    success={(billingInfo) => remakeBillSuccess(billingInfo)}
                />
            }
            {showEditFixedPrice && 
                <EditFixedPriceModal
                    isVisible={showEditFixedPrice}
                    onClose={() => setShowEditFixedPrice(false)}
                    jobDetails={jobDetails}
                    currentPrice={jobDetails.job.get("PriceMax")}
                />
            }
            {showPostPay && 
                <PostPayModal
                    isVisible={showPostPay}
                    onClose={() => setShowPostPay(false)}
                    jobDetails={jobDetails}
                    fetchData={fetchData}

                />
            }
            {showIvaModal && 
                <IvaModal
                    isVisible={setShowIvaModal}
                    onClose={() => setShowIvaModal(false)}
                    rangeOptions={general.ivaRangeOptions}
                    onSaveNewIva={onSaveNewIva}
                    currentValue={jobDetails?.fixedInfo?.get("IVA")}
                />
            }
            <label className="title">Pagos</label>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <div style={{ marginTop: 12,display:"flex", flexDirection:"column" }}>
                        
                        {jobDetails.paymentsArray.map((item, index) => {
                            return (
                                <PaymentCell
                                    key={"payment" + index}
                                    permissions={user.permissions}
                                    buttonHidden={user.permissions && !user.permissions.job.edit }
                                    payment={item}
                                    jobDetails={jobDetails}
                                    user={user}
                                    currentPaymentState={currentPaymentState}
                                    buttonItemSelected_={ option =>{
                                        paymentOptionSelected(item, option)}
                                    }
                                    
                                />
                            );
                        })}
                    </div>
                    <div style={style.total}>
                        <label style={style.totalPriceLabel}>
                            {"Total: " + (jobDetails.job.get("PriceMax")).toFixed(2) + "€"}
                        </label>
                        <label style={style.priceLabel}>
                            {(jobDetails.job.get("PriceMax") * (jobDetails?.fixedInfo?.get("IVA") ? jobDetails?.fixedInfo?.get("IVA") :1.21)).toFixed(2) + "€ con IVA"}
                        </label>
                    </div>
                </div>
            )}
        </section>
    );
}
