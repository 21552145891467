import React from "react";
import MainModal from "./MainModal";

import "../../styles/search/detail/job-details.css";
import "../../"

const ShowPhotosModal = props => {

    return (
        <MainModal
            style={{ height: "750px", width: "1000px" }}
            title={props.title}
            subtitle={""}
            isVisible={props.isVisible}
            onClose={props.onClose}
        > 
            <div className="show-photos-modal-wrapper">
            {
                props.photos.map((media, index) => {
                    const isVideo = Boolean(typeof media.fileName === "string" && media.fileName.match(/\.(mp4|webm|ogg)$/i));
                
                    return isVideo ? (
                        <video key={index} className="fixedPhotos-video" controls>
                            <source src={media.base64} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <img key={index} src={media.base64 ? media.base64 : media} className="fixedPhotos-photo" />
                    );
                })
            }
            </div>
        </MainModal>
    )
}

export default ShowPhotosModal;