import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { hasClassInTree } from "../../../../utils/checkAncestorDOM";

import {MenuList} from 'jobin-ui-components'

export default function PaymentCell({permissions,buttonHidden,payment,jobDetails,currentPaymentState,buttonItemSelected_,user}) {
    const [action] = useState(Jobin.Payment.formatAction(payment));
    const [optionsMenu, setOptionMenu] = useState(null);
    const [showOptions, setShowOptions] = useState(false);

    const style = {
        container: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            marginTop: 12,
            marginBottom: 12,
            alignItems: "center"
        },
        typeLabel: {
            width: 90,
            marginRight: 8
        },
        totalPriceLabel: {
            textAlign: "right",
            fontWeight: "bold"
        },
        priceLabel: {
            textAlign: "right",
            fontSize: 12
        },
        dateLabel: {
            marginRight: 12,
            color: "var(--greyish)"
        },
        stateLabel: {
            marginRight: 12,
            color: action === "Pago pendiente" ? "var(--coral)" : action === "Pago facturado" ? "#FFC900" : null
        },
        button: {
            border: 0,
            outline: 0,
            cursor: "pointer",
            display: buttonHidden ? "none" : "block"
        },
        buttonImage: {
            height: 20,
            width: 20
        },
        buttonContainer: {
            marginLeft: 10
        },
        priceView:{
            display: 'flex',
            flex: 1,
            flexDirection: 'column'
        }
    };

    useEffect(() => {
        setOptionMenu(createOptionsMenu());

        const bodyClickHandler = e => {
            if (!hasClassInTree(e.srcElement, `filter custom options-no-hide`))
                setShowOptions(false);
        };

        document.body.addEventListener("click", bodyClickHandler, false);

        // unmount
        return () => {
            document.body.removeEventListener("click", bodyClickHandler, false);
        };
    }, []);

    const createOptionsMenu = () => {
        return [
            {
                id: "modify-payment-method",
                title: "Modificar método pago",
                disabled: !(
                    payment.get("Action") === "pending" &&
                        payment.get("Method") !== "business"
                )
            },
            {
                id: "mark-as-paid",
                title: "Marcar como pagado",
                disabled: !(
                    payment.get("Action") === "pending" &&
                    (payment.get("Method") === "funding" ||
                        payment.get("Method") === "credit")
                )
            },
            {
                id: "receipt",
                title: "Descargar factura",
                disabled: !payment.has("Receipt")
            },
            {
                id: "modify-receipt",
                title: "Modificar factura",
                disabled: !payment.has("Receipt")
            },
            {
                id: "modify-IVA",
                title: "Modificar IVA",
                disabled: !permissions?.ammount?.IVA
            },
            {
                id: "edit-price",
                title: "Editar precio",
                disabled: !(currentPaymentState != "Done" && permissions?.ammount?.edit)
            },
            {
                id: "run-posPay",
                title: "Ejecutar PostPago",
                disabled: !((jobDetails.job?.get("Business")?.id == "M7t1A74luq" || jobDetails.job?.get("Business")?.id == "ynn52ewgCE")  && !user.permissions.job.edit)
            },
            {
                id: "budget-propousal",
                title: "Ppto enviado al cliente",
                disabled: !permissions?.sendBudgetMilestone
            },
            {
                id: "budget-accepted",
                title: "Notificar ppto aceptado",
                disabled: !permissions?.sendBudgetMilestone
            },
        ];
    };

    const buttonItemSelected = item => {
        setShowOptions(false);
        buttonItemSelected_(item);
    };

    return (
        <section style={style.container}>
            <label style={style.dateLabel}>
                {Jobin.Date.toSortFormat(payment.createdAt).result}
            </label>
            <label style={style.typeLabel}>
                {Jobin.Payment.formatMethod(payment)}
            </label>
            <label style={style.stateLabel}>{action}</label>
            <div style={style.priceView}>
                <label style={style.totalPriceLabel}>
                    {(payment.get("Price")).toFixed(2) + "€"}
                </label>
                <label style={style.priceLabel}>
                    {(payment.get("Price") * (jobDetails?.fixedInfo?.get("IVA") ? jobDetails?.fixedInfo?.get("IVA") :1.21)).toFixed(2) + "€ con IVA"}
                </label>
            </div>
            <div className={`filter custom options-no-hide`} style={style.buttonContainer}>
                <button
                    style={style.button}
                    onClick={() => setShowOptions(!showOptions)}
                >
                    <img
                        style={style.buttonImage}
                        src={require("../../../../assets/option-dots.svg")}
                        alt="dots"
                    />
                </button>
                {showOptions && (
                    <MenuList
                        items={optionsMenu}
                        buttonItemSelected={buttonItemSelected}
                        left={true}
                        menuStyle={{top:-90,right:50}}
                    />
                )}
            </div>
        </section>
    );
}
