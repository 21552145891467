import {
    privateKeyCrypto
} from '../constants/credentials';


import { useCallback } from "react";

function encodeBase64Url(input) {
  const base64 = btoa(String.fromCharCode(...input));
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

function decodeBase64Url(input) {
  const base64 = input.replace(/-/g, "+").replace(/_/g, "/");
  const padding = "=".repeat((4 - base64.length % 4) % 4);
  const decoded = atob(base64 + padding);
  return new Uint8Array(decoded.split("").map((c) => c.charCodeAt(0)));
}

export function useCrypto() {

  const getKey = useCallback(async() => {
    const encoder = new TextEncoder();
    const keyBuffer = encoder.encode(privateKeyCrypto);

    const hmac = await crypto.subtle.importKey(
      "raw",
      keyBuffer,
      { name: "HMAC", hash: "SHA-256" },
      false,
      ["sign", "verify"]
    );

    const signature = await crypto.subtle.sign("HMAC", hmac, keyBuffer);
    return crypto.subtle.importKey(
      "raw",
      new Uint8Array(signature).slice(0, 32),
      { name: "AES-GCM" },
      false,
      ["encrypt", "decrypt"]
    );
  }, []);

  const deriveIV = useCallback(async (key)=> {
    const encoder = new TextEncoder();
    const keyBuffer = encoder.encode(key);

    const hmac = await crypto.subtle.importKey(
      "raw",
      keyBuffer,
      { name: "HMAC", hash: "SHA-256" },
      false,
      ["sign", "verify"]
    );

    const signature = await crypto.subtle.sign("HMAC", hmac, keyBuffer);
    return new Uint8Array(signature).slice(0, 12);
  }, []);

  const encrypt = useCallback(
    async (plaintext) => {
      const key = await getKey();
      const iv = await deriveIV(privateKeyCrypto);

      const encodedText = new TextEncoder().encode(plaintext);

      const encryptedBuffer = await crypto.subtle.encrypt(
        { name: "AES-GCM", iv },
        key,
        encodedText
      );

      return encodeBase64Url(new Uint8Array(encryptedBuffer));
    },
    [getKey, deriveIV]
  );

  const decrypt = useCallback(
    async (encryptedBase64) => {
      const key = await getKey();
      const iv = await deriveIV(privateKeyCrypto);

      const encryptedData = decodeBase64Url(encryptedBase64);

      const decryptedBuffer = await crypto.subtle.decrypt(
        { name: "AES-GCM", iv },
        key,
        encryptedData
      );

      return new TextDecoder().decode(decryptedBuffer);
    },
    [getKey, deriveIV]
  );

  return { encrypt, decrypt };
}