import React, {  useState, useEffect } from "react";
import {Loading} from 'jobin-ui-components'
import { useStateValue } from "../../state/context";
import {searchTableControlOpsData} from "../../utils/ParserUtilities"

import BarDiagram from "./components/BarDiagram.js"
import ContentBox from "./components/ContentBox.js"
import DataTable from "./components/DataTable.js"
import MapChart from "./components/MapChart.js"


import "../../styles/search/result-table.css";

export default function ResultDashboard(props) {

    const [{ kpisDetail }, dispatch] = useStateValue();
    const [data] = useStateValue();
    const [ accordeon ,  setAccordeon] = useState([false, false, false]);
    const [ controlOpsData ,  setControlOpsData] = useState([]);

    const business = !(data.user?.permissions?.job?.edit);


    useEffect(()=>{
      getControlOpsData();
    },[])

    useEffect(()=>{
      getControlOpsData();
    },[kpisDetail.filters.startDate, kpisDetail.filters.endDate])

    const getControlOpsData = async () => {
      const result = await searchTableControlOpsData(kpisDetail.filters.startDate, kpisDetail.filters.endDate);
      setControlOpsData(result);
    }

    const manageAcordeon = (type) => {
      if(type == "operators") setAccordeon([true, false, false])
      if(type == "distribution") setAccordeon([false, false, true])
      if(type == "controlOps") setAccordeon([false, true, false])
    }

    if (kpisDetail.loading) {
        return (
            <section className="kpis-loadingContainer display-flex flex-align-center flex-justify-center">
                <Loading />
            </section>
        );
    } else {
        return (
            <div className="result-table-container kpis-main" >
              <div className="display-flex components-container flex-justify-between">
                <BarDiagram  globalContext="N° de Servicios Diarios" color="var(--coral)" data={kpisDetail?.results?.dailyServices}/>
                <ContentBox globalContext="GLOBAL" data={kpisDetail?.results?.global} isSingle={true} />
                <div className="display-flex flex-column boxContainers">
                  <ContentBox globalContext="LEADS" data={kpisDetail?.results?.leads} isSingle={false} style={{marginBottom: 5}} />
                  <ContentBox globalContext="PRECIO CERRADO" data={kpisDetail?.results?.fixed} isSingle={false} />
                </div>
                <MapChart data={kpisDetail?.results?.regions && kpisDetail?.results?.regions.all} ></MapChart>
              </div>
              <div className="display-flex components-container flex-justify-between">
                {!business && (
                  <>
                    <BarDiagram globalContext="Ingresos Diarios"  color="var(--kpis_green)" data={kpisDetail?.results?.dailyProfits}/>
                    <div className={accordeon[0] == true ? "opened AccordeonKpis":"closed AccordeonKpis"}>
                      <span className="accordeonTrigger" onClick={()=>{manageAcordeon("operators")}}>operadores & wollers</span>
                      <div className="accordeonContainer">
                        <DataTable data={kpisDetail?.results?.wolers} thead="Woller" globalContext="Ranking Top 10 Wollers" />
                        <DataTable data={kpisDetail?.results?.agents} thead="Ops" globalContext="Control Ops"/>
                      </div>
                    </div>
                  </>    
                )}
                
                <div className={!accordeon[1] == true ? "opened AccordeonKpis":"closed AccordeonKpis"}>
                  <span className="accordeonTrigger" onClick={()=>manageAcordeon("distribution")}>Distribucion</span>
                  <div className="accordeonContainer">
                    <DataTable data={kpisDetail?.results?.guilds} thead="Gremio" globalContext="Distribucion por Gremio" />
                    <DataTable data={kpisDetail?.results?.regions && kpisDetail?.results?.regions.top10} thead="Provincia" globalContext="Distribucion por Provincia"/>
                  </div >
                </div >
                {!data.user.isB2b && (
                  <div className={!accordeon[2] == true ? "opened AccordeonKpis":"closed AccordeonKpis"}>
                    <span className="accordeonTrigger" onClick={()=>{manageAcordeon("controlOps")}}>ControlOps</span>
                    <div className="accordeonContainer">
                      <div className="controlOpsTable-wrapper">
                        <p className="controlOpsTable-title">Control Agentes / Leads</p>
                        <div className="controlOpsTable-header">
                          <div className="controlOpsTable-cell bg-color-green">
                            <p className="controlOpsTable-header-p">Agente</p>
                          </div>
                          <div className="controlOpsTable-cell bg-color-blue">
                            <p className="controlOpsTable-header-p">N Match</p>
                          </div>
                          <div className="controlOpsTable-cell bg-color-blue">
                            <p className="controlOpsTable-header-p">N Cancel</p>
                          </div>
                          <div className="controlOpsTable-cell bg-color-green">
                            <p className="controlOpsTable-header-p">Lead Medio</p>
                          </div>
                          <div className="controlOpsTable-cell bg-color-green">
                            <p className="controlOpsTable-header-p">€ Total</p>
                          </div>
                        </div>
                      { controlOpsData.map((crmUser,id) => (
                          <div key={id+"crmUser"+id} className="controlOpsTable-header">
                            <div className="controlOpsTable-cell">
                              <p className="controlOpsTable-table-p">{crmUser?.name}</p>
                            </div>
                            <div className="controlOpsTable-cell">
                              <p className="controlOpsTable-table-p">{crmUser?.match}</p>
                            </div>
                            <div className="controlOpsTable-cell">
                              <p className="controlOpsTable-table-p">{crmUser?.cancel}</p>
                            </div>
                            <div className="controlOpsTable-cell">
                              <p className="controlOpsTable-table-p">{crmUser?.mediumLead != "NaN" ? crmUser?.mediumLead : 0} €</p>
                            </div>
                            <div className="controlOpsTable-cell">
                              <p className="controlOpsTable-table-p">{crmUser?.totalPrice} €</p>
                            </div>
                          </div>
                        ))}
                      </div>

                    </div >
                  </div >
                )}
                
              </div>
            </div>
        );
    }
}
