import React, { useState } from "react";
import Jobin from "jobin-client";
import { useStateValue } from "../../state/context";
import { Loading, MenuList } from "jobin-ui-components";

import "../../styles/search/result-table.css";

export default function ResultTable(props) {
    const [{ billingSearch, user }, dispatch] = useStateValue();
    const [showMenuList, setShowMenuList] = useState(null);
    const menuItems = payment => [
        {
            id: "receipt",
            title: "Ver factura",
            disabled: !payment.has("Receipt")
        },
        {
            id: "edit",
            title: "Editar factura",
            disabled: !(
                user &&
                user.permissions &&
                user.permissions.billing &&
                user.permissions.billing.edit &&
                payment.get("Type") === "joberPayoff"
            )
        },
        {
            id: "remake-receipt",
            title: "Regenerar factura",
            disabled:
                !payment.has("ReceiptName") ||
                !payment.has("ReceiptInfo") ||
                (!payment.has("User") && !payment.has("Business"))
        },
        {
            id: "job",
            title: "Ir a trabajo",
            disabled: !payment.has("Job")
        }
    ];


    const style = {
        loadingContainer: {
            display: "flex",
            flex: 1,
            backgroundColor: "var(--soft-grey)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
        },
        tableContainer: {
        },
        logoImage: {
            height: 36,
            width: 36,
            backgroundColor: "var(--soft-grey)",
            borderRadius: 18,
            overflow: "hidden",
            objectFit: "cover"
        },
        starImage: {
            height: 18,
            width: 18,
            overflow: "hidden",
            objectFit: "cover"
        }
    };

    const buttonItemSelected = async (option, item) => {
        if (option.id === "job") {
            props.history.push({
                pathname: "/job/" + item.get("Job").id,
                state: {
                    inner: true
                }
            });
        } else if (option.id === "edit") {
            props.history.push({
                pathname: "/billing/edit/" + item.id,
                state: {
                    inner: true
                }
            });
        } else if (option.id === "receipt") {
            const win = window.open(item.get("Receipt").url(), "_blank");
            win.focus();
        } else if (option.id === "remake-receipt") {
            dispatch({
                type: "BILLING_SEARCH_LOADING",
                loading: true
            });
            const result = await Jobin.PDF.remakeBill(item);
            if (result.status === "ok") {
                const win = window.open(result.result, "_blank");
                win.focus();
            } else {
                alert(result.result);
            }
            dispatch({
                type: "BILLING_SEARCH_LOADING",
                loading: false
            });
        }
    };

    const formatType = type => {
        switch (type) {
            case "pack":
                return "Pack";
            case "subscription":
                return "Suscripción";
            case "jobinPay":
                return "Comisión Jobin Pay";
            case "fixed":
                return "Precio cerrado";
            case "transportation":
                return "Transporte";
            case "payoff":
                return "Liquidación empresas";
            case "joberPayoff":
                return "Liquidación Jobers";
            case "custom":
            default:
                return "Otros";
        }
    };

    const PaymentsTable = key => {
        return (
            <table>
                <thead>
                    <tr>
                        {billingSearch.itemsTitle.map((title, index) => {
                            return (
                                <th key={`${key}-${title}-${index}`}>
                                    {title}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {billingSearch.items.map((payment, index) => {
                        const action = Jobin.Payment.formatAction(payment);
                        let price = payment.has("Price")
                            ? payment.get("Price")
                            : 0;
                        return (
                            <tr key={`${key}-${payment.id}-${index}`}>
                                <td>
                                    {payment.get("Method") === "cash" ? (
                                        <img
                                            src={require("../../assets/cash.svg")}
                                            alt="cash"
                                           
                                        />
                                    ) : payment.get("Method") === "linkPayment" ? (
                                        <img
                                            src={require("../../assets/link-solid.svg")}
                                            alt="linkPayment"
                                            
                                        />
                                    ) : payment.get("Method") === "paycard" ? (
                                        <img
                                            style={{ height: "24px" }}
                                            src={require("../../assets/stripe.png")}
                                            alt="paycard"
                                        />
                                    ) : payment.get("Method") === "tpv" ? (
                                        <img
                                            src={require("../../assets/card.svg")}
                                            alt="tpv"
                                        />
                                    ) : payment.get("Method") === "invoice" ? (
                                        <img
                                            src={require("../../assets/email.svg")}
                                            alt="invoice"
                                        />
                                    ) : payment.get("Method") === "funding" ? (
                                        <img
                                            src={require("../../assets/nemuru.png")}
                                            alt="funding"
                                        />
                                    ) : payment.get("Method") === "transfer" ? (
                                        <img
                                            src={require("../../assets/transfer.svg")}
                                            alt="transfer"
                                        />
                                    ) : payment.get("Type") ===
                                      "joberPayoff" ? (
                                        <img
                                            style={{ height: 25, width: 25 }}
                                            src={require("../../assets/payoff.png")}
                                            alt="joberPayoff"
                                        />
                                    ) : payment.get("Type") === "payoff" ? (
                                        <img
                                            src={require("../../assets/business.svg")}
                                            alt="payoff"
                                        />
                                    ) : payment.get("Type") === "custom" ? (
                                        <img
                                            src={require("../../assets/custom.svg")}
                                            alt="custom"
                                        />
                                    
                                    ) : null}
                                </td>
                                <td>
                                    {
                                        Jobin.Date.toSortFormat(
                                            payment.get("createdAt")
                                        ).result
                                    }
                                </td>
                                <td>{formatType(payment.get("Type"))}</td>
                                <td>{payment.get("ReceiptName")}</td>
                                <td
                                    style={{
                                        color:
                                            action === "Pago pendiente"
                                                ? "var(--coral)"
                                                : action === "Pago facturado"
                                                ? "#FFC900"
                                                : "var(--black-wolly-darker)"
                                    }}
                                >
                                    {action}
                                </td>
                                <td
                                    style={{
                                        color:
                                            price < 0
                                                ? "var(--coral)"
                                                : "var(--black-wolly-darker)"
                                    }}
                                >
                                    {price.toFixed(2) + " €"}
                                </td>
                                <td
                                    style={{
                                        color:
                                            price < 0
                                                ? "var(--coral)"
                                                : "var(--black-wolly-darker)"
                                    }}
                                >
                                    {(price * 1.21 - price).toFixed(2) + " €"}
                                </td>
                                <td
                                    style={{
                                        fontWeight: 700,
                                        color:
                                            price < 0
                                                ? "var(--coral)"
                                                : "var(--black-wolly-darker)"
                                    }}
                                >
                                    {(price * 1.21).toFixed(2) + " €"}
                                </td>
                                <td
                                    onClick={() => {
                                        showMenuList === index
                                            ? setShowMenuList(null)
                                            : setShowMenuList(index);
                                    }}
                                >
                                    <img
                                        src={require("../../assets/option-dots.svg")}
                                        alt="option"
                                    />
                                    {showMenuList === index && (
                                        <MenuList
                                            style={{
                                                left: "unset",
                                                top: "unset",
                                                right: 24
                                            }}
                                            items={menuItems(payment)}
                                            buttonItemSelected={option =>
                                                buttonItemSelected(
                                                    option,
                                                    payment
                                                )
                                            }
                                        />
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    if (billingSearch.loading) {
        return (
            <section style={style.loadingContainer}>
                <Loading />
            </section>
        );
    } else {
        return (
            <div
                className="result-table-container"
                style={style.tableContainer}
            >
                {billingSearch.items.length > 0 ? (
                    <PaymentsTable key="lead" />
                ) : (
                    <section style={{ padding: 8 }}>
                        <h3
                            style={{
                                fontSize: 25,
                                marginTop: 0,
                                marginBottom: 8
                            }}
                        >
                            No se han encontrado resultados
                        </h3>
                        <p>
                            Modifica los filtros de tu búsqueda para ampliar el
                            rango.
                        </p>
                    </section>
                )}
            </div>
        );
    }
}
