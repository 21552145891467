import {apiId, jsKey, masterKey, urlServer} from '../constants/credentials';
import axios from "axios"
let Parse = require("parse");

Parse.initialize(apiId, jsKey);
Parse.serverURL = urlServer;
Parse.User.enableUnsafeCurrentUser();

//get/fetch/find/search
export const getSugestedPrice = async (jobId)=>{
    let res = await Parse.Cloud.run("getSugestedPrice",{
      
        jobId:jobId
        
    });
    
    return res
    
}
export const getAllJobs = async (jobId)=>{
    let res = await Parse.Cloud.run("getAllJobs");
    
    return res
    
}
export const getBusinessData = async (objectId)=>{
    let res = await Parse.Cloud.run("getBusinessData",{
        businessId:objectId
    });
    
    return res
    
}
export const getNotificationsPhotos = async (objectId)=>{
    let data = {
        jobId: objectId
    }

    let res = await Parse.Cloud.run("getNotificationsPhotos", data);
    return res
    
}
export const getWollesbyJob = async (objectId)=>{
    let data = {
        jobId: objectId
    }

    let res = await Parse.Cloud.run("getWollesbyJob", data);
    return res
}   
export const getParsedInfo = async (notification)=>{
    let res = await Parse.Cloud.run("getParsedInfo",{
        id:notification

    });

    return res
    
}
export const getCrmUserData = async (objectId)=>{
    let data = {
        userId: objectId
    }
    let res = await Parse.Cloud.run("getCrmUserData", data);
    return res
}
export const getUserData = async (objectId)=>{
    let data = {
        id: objectId
    }

    let res = await Parse.Cloud.run("getUserById", data);
    return res
    
}
export const getContractorDataByField = async (field,value,pointerClass)=>{
    let data = {
        field:field,
        value:value,
        pointerClass: pointerClass
    }

    let res = await Parse.Cloud.run("getContractorDataByField", data);
    return res
    
}
export const getWollerProfile = async (objectId)=>{
    let data = {
        wollerId: objectId
    }

    let res = await Parse.Cloud.run("getWollerProfile", data);
    return res
    
}
export const getFormattedAddresDetail = async (objectId)=>{
    let data = {
        jobId: objectId
    }

    let res = await Parse.Cloud.run("getFormattedAddresDetail", data);
    return res
    
}
export const getFiltredJobs = async (userId, JobType, startDate, endDate, provinces,guilds,  jobId,limit)=>{
    let data = {
        userId:userId,
        JobType:JobType,
        provinces:provinces,
        guilds:guilds,
        startDate:startDate,
        endDate:endDate,
        jobId:jobId,
        limit:limit
    }

    let res = await Parse.Cloud.run("getFiltredJobs", data);
    return res
}
export const getNotificationsByJob = async (jobsArray)=>{
    let data = {
        jobsArray:jobsArray
    }

    let res = await Parse.Cloud.run("getNotificationsByJob", data);
    var wollersByJob =[]
	res.map((notif)=> {
		wollersByJob[`${notif.get("Jobs").id}`] = []	
	})
	res.forEach((notif)=> {
		
		if(wollersByJob[`${notif.get("Jobs").id}`].length>0){
			wollersByJob[`${notif.get("Jobs").id}`]= [...wollersByJob[`${notif.get("Jobs").id}`],notif.get("FromUser")]
		}else{
			wollersByJob[`${notif.get("Jobs").id}`]= [notif.get("FromUser")]
		}
		
	})
    return wollersByJob

}
export const getAgentsByParams = async (field, clouse)=>{
    let data = {
        field:field,
        clouse:clouse
    }

    let res = await Parse.Cloud.run("getAgentsByParams", data);
   
    return res

}
export const getAllWollers = async ()=>{
    

    let res = await Parse.Cloud.run("getWollers");
   
    return res
    
}
export const GetAllAgents = async ()=>{
    
    
    let res = await Parse.Cloud.run("getAllAgents");
    
    return res
    
}
export const getAssignRules = async ()=>{
   

    let res = await Parse.Cloud.run("getAssignRules");
   
    return res

}
export const getFilteredJobsMap = async (selectedVerticals, selectedProvinces, selectedBusiness, selectedAgents, dateRange, selectedTotalSlots) => {

    let jobs = await Parse.Cloud.run("getFilteredJobsMap", {
                        selectedVerticals: selectedVerticals, 
                        selectedProvinces: selectedProvinces,
                        selectedBusiness: selectedBusiness,
                        dateRange: dateRange,
                        selectedTotalSlots: selectedTotalSlots,
                        selectedAgents: selectedAgents
                    });
    
    return jobs;
}
export const getFatherVerticals = async () => {
    let res = await Parse.Cloud.run("getFatherVerticals");
    return res;
}
export const getAgentsForMaps = async () => {
    let res = await Parse.Cloud.run("getAgentsForMaps");
    return res;
}
export const getVerticalsWithFaters = async () => {
    let res = await Parse.Cloud.run("getVerticalsWithFaters");
    return res;
}
export const getMatchesOfJobs = async (jobId) => {
    let res = await Parse.Cloud.run("getMatchesOfJobs",{
        jobId: jobId,
    });

    return res;
}
export const getMatchFilteredWollers = async ({phone, name, location, distance, selectedLevels, selectedLeads, type,contractor,onlyPromo,noGuild}) => {

    const data = {
        phone: phone,
        name: name,
        location: location,
        distance: distance,
        levels: selectedLevels,
        leads: selectedLeads,
        type: type,
        active: true,
        onlyPromo:onlyPromo,
        contractor: contractor,
        noGuild:noGuild
    }
    
    let res = await Parse.Cloud.run("getMatchFilteredWollers", data);
    return res;
}
export const getNearGarages = async (latitude, longitude) => {

    const data = {
        latitude: latitude,
        longitude: longitude
    }

    let res = await Parse.Cloud.run("getNearGarages", data);
    return res;
}
export const getWollersByVerticalsForMap = async (verticals) => {

    const data = {
        verticals: verticals
    }

    let res = await Parse.Cloud.run("getWollersByVerticalsForMap", data);
    return res;
}
export const getNearJobsByWollerId = async (wollerId, distance) => {

    const data = {
        wollerId: wollerId,
        distance: distance
    }

    let res = await Parse.Cloud.run("getNearJobsByWollerId", data);
    return res;
}
export const getJobById = async (jobId) => {

    const data = {
        jobId: jobId
    }

    let res = await Parse.Cloud.run("getJobById", data);
    return res;
}
export const getJobAXAMediator = async (title) => {

    const data = {
        title: title
    }

    let res = await Parse.Cloud.run("getJobAXAMediator", data);
    return res;
}
export const getLMPDFData = async (jobId) => {

    const data = {
        jobId: jobId
    }

    let res = await Parse.Cloud.run("getLMPDFData", data);
    return res;
}
export const getCRMUserTokenById = async (userId) => {

    const data = {
        userId: userId
    }

    let res = await Parse.Cloud.run("getCRMUserTokenById", data);
    return res;
}
export const getGenericPdfPCData = async (jobId) => {

    const data = {
        jobId: jobId
    }

    let res = await Parse.Cloud.run("getGenericPdfPCData", data);   

    return res;
}
export const getFullWollerProfile = async ({wollerId}) => {
    const data = {
       wollerId:wollerId
    }

    let res = await Parse.Cloud.run("getFullWollerProfile", data);

    return res
}
export const getFavoritePacks = async ()=> {
    

    let res = await Parse.Cloud.run("getFavoritePacks");

    return res
}
export const getPurchasedJobsBiPro = async (wollerId, type) => {
    const data = {
        wollerId:wollerId,
        type: type
    }

    let res = await Parse.Cloud.run("getPurchasedJobsBiPro", data);

    return res
} 
export const getJobsType = async (userId) => {
    let res = await Parse.Cloud.run("getTasks",{ joberId: userId });

    return res
}
export const getCertificationStatus = async (userId) => {
    let res = await Parse.Cloud.run("getCertificationStatus",{ userId: userId });

    return res
}
export const getAllCampains = async () => {

    let res = await Parse.Cloud.run("getAllCampains", {});

    return res; 
}
export const getCampainClick = async (dateRange, campainId) => {

    let res = await Parse.Cloud.run("getCampainClick", {dateRange: dateRange, campainId: campainId});

    return res; 
}
export const getPaymentData = async (userId) => {
    let res = await Parse.Cloud.run("getPaymentData",{userId:userId});

    return res
}
export const getAddressFromJob = async (jobId) => {//ToDo migrate all parse framework use to backend
    try {
        const jobQuery = new Parse.Query(Parse.Object.extend("JobAsset"));
        jobQuery.equalTo("Jobs", Parse.Object.extend("Jobs").createWithoutData(jobId));
        jobQuery.include("Building", "Asset");
        return await jobQuery.first();
    } catch (error) {
        console.error(error)
        return error
    }
}
export const getUsersByPromotion = async (promotionId) => {//ToDo migrate all parse framework use to backend
    try {
        const promotionQuery = new Parse.Query(Parse.Object.extend("UserPromotion"));
        promotionQuery.equalTo("Promotion", Parse.Object.extend("Promotion").createWithoutData(promotionId));
        promotionQuery.include("User");
        const promotionResult = await promotionQuery.find();
        return promotionResult.map(promotion => promotion.get("User")?.id);
    } catch (error) {
        console.error(error)
        return error
    }
}
export const getClientByPhone = async (phone) => {//ToDo migrate all parse framework use to backend
    try {
        let user = await new Parse.Query(Parse.User).equalTo("Phone", phone).first();
        return user;
    } catch (error) {
        console.error(error)
        return error
    }
}
export const getConfigByParam = async ({params}) => {
    let data = {param:params}
    return  await Parse.Cloud.run("getConfigByParam",data);
}
export const findGuildByBusiness = async ({businessId}) => {
    let data = {
        businessId:businessId
    }
    let res = await Parse.Cloud.run("findGuildByBusiness",data);
    return res;
}
export const findJobByIDs = async (id,jobType) => {

    const data = {
        id:id,
        jobType:jobType
    }
        
    let res = await Parse.Cloud.run("findJobByIDs", data);
    return res;
}
export const findJobsByFilters = async ({type,startDate,endDate,provinces,guilds,constructorGuilds,levels,slots,jobStates,agents,businesses,budget,onlyIncidences,appointmentStart,appointmentEnd,limit,page,userType}) => {

    const data = {
        jobType:type,//string
        startDate:startDate,//date
        endDate:endDate,//date
        provinces:provinces,//[string]
        guilds:guilds,//[string]
        constructorGuilds:constructorGuilds,//[string]
        levels:levels,//[string]
        slots:slots,//[string]
        jobStates:jobStates,//[string]
        agents:agents,//[string]
        businesses:businesses,//[string]
        budget:budget,//[string]
        onlyIncidences:onlyIncidences,//boolean
        appointmentStart:appointmentStart,//Date
        appointmentEnd:appointmentEnd,//Date
        limit:limit,//int
        page:page,//int
        userType:userType//int
    }
    
    let res = await Parse.Cloud.run("findJobsByFilters", data);
    return res;
}
export const findJobsforBiPro = async ({startDate,endDate,provinces,guilds,slots,wollerId,distance,levels}) => {

    const data = {
        startDate:startDate,//date
        endDate:endDate,//date
        provinces:provinces,//[string]
        guilds:guilds,//[string]
        slots:slots,//[int]
        levels:levels,//[int]
        distance:distance,//int
        wollerId:wollerId//string
    }
    
    let res = await Parse.Cloud.run("findJobsforBiPro", data);
    return res;
}
export const fetchKpis = async (statDate, endDate, provinces, guilds, business)=>{

    
}
export const fetchOpsNotification = async (jobId) => {

    let data = {
        jobId: jobId
    }
    
    let res = await Parse.Cloud.run("fetchOpsNotification", data);
    return res;
}
export const searchWollers = async (levelArray, distance, location, minPoints, minLeads, lastConnectionStartDate, lastConnectionEndDate, guilds, phone, limit, message, business, promotions)=>{
   
    let data = {
        levelArray:levelArray,
        distance:distance,
        location:location,
        minPoints:minPoints,
        minLeads:minLeads,
        lastConnectionStartDate:lastConnectionStartDate,
        lastConnectionEndDate:lastConnectionEndDate,
        guilds:guilds,
        phone:phone,
        limit:limit,
        message:message,
        business: business,
        promotions: promotions
    }

    let res = await Parse.Cloud.run("searchWollers", data);
    return res

}
export const searchWollerChat = async (id)=>{
    let data = {
       id:id
    }

    let res = await Parse.Cloud.run("searchWollerChat", data);
    return res

}
export const searchWollerDocuments = async (id)=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
       id:id
    }

    let res = await Parse.Cloud.run("searchWollerDocuments", data);
    return res

}
export const searchAgentsById = async (field, clouse)=>{
    let data = {
        field:field,
        clouse:clouse
    }

    let res = await Parse.Cloud.run("searchAgentsById", data);
   
    return res

}
//edit/update
export const changeBudgetData = async (_state, _job, isGoldWoller)=>{

    
    let res = await Parse.Cloud.run("setBudgetData",{
        jobId:_job.id,
        state:_state.state,
        origin:_state.origin,
        coments: isGoldWoller ? "Vía JustNow" :_state.coments,
        userId:_state.userId
    });
    
     return res
    
}
export const editUserData = async (dataObject, objectId,isWollerData)=>{
    let res = await Parse.Cloud.run("editUserData",{
        key:dataObject.key,
        value:dataObject.value,
        userId:objectId,
        isWollerData:isWollerData
    });
    return res
}
export const editJobData = async (dataObject, objectId)=>{
    let res = await Parse.Cloud.run("updateJobData",{
        key:dataObject.key,
        value:dataObject.value,
        jobId:objectId
        
    });
    
    
    return res
    
}
export const editExtras = async (dataObject, objectId)=>{
    let res = await Parse.Cloud.run("updateFixedExtras",{
        extras:dataObject,
        jobId:objectId
        
    });
    
    return res
    
}
export const editOpsNotification = async (jobId, create, type) => {

    const data = {
        jobId: jobId,
        create: create,
        type: type
    }

    let res = await Parse.Cloud.run("editOpsNotification", data);
    return res;
}
export const updateDescription = async (data)=>{
    let res = await Parse.Cloud.run("updateDescription", data);
    return res
}
export const updateSugestedPrice = async (data)=>{

    let res = await Parse.Cloud.run("updateSugestedPrice", data);
    return res
}
export const updatePriorities = async (rules)=>{
    let data = {
        rules:rules,
    }

    let res = await Parse.Cloud.run("updatePriorities",data);
   
    return res

}
export const updateVerified = async (jobId,isVerified) => {
    let data = {
        jobId: jobId,
        verified: isVerified
    }
    let res = await Parse.Cloud.run("updateVerified", data);
    return res
}
export const updateTypeJobs = async ({jobId,typeService,crmId}) => {
    let data = {
        id: jobId,
        typeService: typeService,
        crmId: crmId
    }
    let res = await Parse.Cloud.run("updateTypeJobs", data);
    return res
}
export const updateACRGuild = async ({jobId,guildId}) => {
    let data = {
        jobId: jobId,
        guildId: guildId ? guildId : undefined,
    }
    let res = await Parse.Cloud.run("updateACRGuild", data);
    return res
}
export const updateACRDefects = async ({jobId,defectId}) => {
    let data = {
        jobId: jobId,
        defectId: defectId,
    }
    let res = await Parse.Cloud.run("updateACRDefects", data);
    return res
}
export const updateNJobs = async (wollerId, nJobs) => {

    const data = {
        nJobs: nJobs,
        wollerId: wollerId
    }

    let res = await Parse.Cloud.run("updateNJobs", data);
    return res;
}
export const updateFixedPrice = async (jobId, newPrice) => {

    const data = {
        jobId: jobId,
        newPrice: newPrice
    }

    let res = await Parse.Cloud.run("updateFixedPrice", data);

    return res;
}
export const updateWollerActive = async (userId, active) => {
    let res = await Parse.Cloud.run("updateWollerActive",{userId:userId, active: active});

    return res;
}
export const checkIfWollerBuyJob = async (id,userId)=>{
    let data = {
        jobId: id,
        userId: userId
    }
    let res = await Parse.Cloud.run("checkIfWollerBuyJob", data);
    return res
    
}
export const changeAgentParams = async (id,Lastname=null, Active=null, hashedPassword=null, Name=null, Department=null,email=null,Business=null,User=null,Permissions=null)=>{
    let data = {
        id:id,
        Lastname:Lastname,
        Active:Active,
        hashedPassword:hashedPassword,
        Name:Name,
        Department:Department,
        email:email,
        Business:Business,
        User:User,
        Permissions:Permissions
    }
    
    let res = await Parse.Cloud.run("setAgentParams",data);
    
    return res
    
}
export const updateFixedProperty = async ({id,property, value})=>{
    let data = {
        id:id,
        property:property,
        value:value
    }
    
    let res = await Parse.Cloud.run("updateFixedProperty",data);
    
    return res
    
}
export const changeAppointment = async ({jobId,from, date,type})=>{
    let data = {
        jobId:jobId,
        from:from,
        date:date,
        type:type
    }
    
    let res = await Parse.Cloud.run("changeAppointment",data);
    
    return res
    
}
export const finishJob = async ({jobId,wollerId,fixedInfoId,sessionToken})=>{
    let data = {
        jobId:jobId,
        wollerId:wollerId,
        fixedInfoId:fixedInfoId,
        sessionToken:sessionToken
    }
    
    let res = await Parse.Cloud.run("finishJob",data);
    
    return res
    
}
//set/save
export const setReaded = async (id)=>{
    let data = {
       id:id
    }

    let res = await Parse.Cloud.run("setReaded", data);
    return res

}
export const setAssignRules = async (id,agent=null, provinces=null, guilds=null, business=null, active=null)=>{
    let data = {
        id:id,
        agent:agent,
        provinces:provinces,
        guilds:guilds,
        business:business,
        isActive:active
    }

    let res = await Parse.Cloud.run("setAssignRules",data);
   
    return res

}
export const setVerticalPrices = async (id,wollerPrice, userPrice) => {

    const data = {
        verticalId: id,
        wollerPrice:parseFloat(wollerPrice),
        userPrice:parseFloat(userPrice)
    }
    
    let res = await Parse.Cloud.run("setVerticalPrices", data);
    return res;
}
export const setCampainClick = async (campainId, userId) => {
    return;

    const data = {
        from: "bipro",
        campainId: campainId,
        userId: userId ? userId : undefined
    }
    
    let res = await Parse.Cloud.run("setCampainClick", data);

    return res; 
}
export const saveMessage = async (message,user)=>{
    message.user=user.id
    let res = await Parse.Cloud.run("saveMessage", message);
    return res
}
export const closeAuctionJob = async (objectId)=>{
    let data = {
        jobId: objectId
    }

    let res = await Parse.Cloud.run("updateState", data);
    return res
    
}
export const cancelJob = async ({jobId, action, summary, iban,sessionToken, comments}) =>{
    let data = {
        jobId:jobId,
        action:action,
        summary:summary,
        iban:iban,
        sessionToken:sessionToken,
        comments:comments
    }

    let res = await Parse.Cloud.run("cancelJob", data);
    return res
    
}
//
export const sendMailBudgetState = async (_dataJob,coments,budget)=>{
    if(_dataJob.business && _dataJob.business.id === "Uo1ncXDbA2"){

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization':"Bearer " + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjEiLCJlbWFpbCI6InVzZXJAcGFzcyJ9.OQkzS6qH5gWNWpyD5AJn7ncYw2Fs4PLi-_jXgR9b0Oo'
            }
        };
        let service = _dataJob.title.split(" ")
        let data = {

            referencia:service[0],
            estado:budget.state ==="estimated"? "Estimado":"Desestimado",
        }
    
        axios.post('https://justnow.wolly.grupomobius.com/api/pelayo-reply', data, axiosConfig)
            .then((res) => {
                return  res
            })
            .catch((err) => {
                console.error("AXIOS ERROR: ", err);
                return err
            })

    }else{
        return {data:[_dataJob, coments, budget], state:"not pelayo"}
    }
    
}
export const sendBudgetAproval = async ({agentId,jobId,price,externalId,tax=21,businessId})=>{
let data = {
        agentId:agentId,
        jobId:jobId,
        price:price,
        externalId:externalId,
        tax:tax,
        businessId:businessId

    }
    
    let res = await Parse.Cloud.run("sendBudgetAprovalToPartner", data);
    return res
    
}
export const sendRegetBudgetContact = async ({agentId,jobId,price,externalId,tax=21,businessId})=>{
let data = {
        agentId:agentId,
        jobId:jobId,
        price:price,
        externalId:externalId,
        tax:tax,
        businessId:businessId

    }
    
    let res = await Parse.Cloud.run("sendBudgetContactToPartner", data);
    return res
    
}
export const DeleteAgent = async (id)=>{
    let data = {
        id:id,
    }

    let res = await Parse.Cloud.run("deleteAgent",data);
   
    return res

}
export const createAssignRules = async (prior)=>{
   

    let res = await Parse.Cloud.run("createAssignRules",{prior:prior});
   
    return res

}
export const deleteAssignRules = async (id)=>{
    let data = {
        id:id,
    }

    let res = await Parse.Cloud.run("deleteAssignRules",data);
   
    return res

}
export const acceptCertificationFile = async ({crmUserId, certificationId, fileName})=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        crmUserId:crmUserId,
        certificationId:certificationId,
        fileName:fileName,
    }

    let res = await Parse.Cloud.run("acceptCertificationFile",data);
   
    return res

}
export const rejectCertificationFile = async ({crmUserId, certificationId, fileName, reason})=>{
    Parse.initialize(apiId, jsKey);
    Parse.serverURL = urlServer;
    Parse.User.enableUnsafeCurrentUser();
    let data = {
        crmUserId:crmUserId,
        certificationId:certificationId,
        fileName:fileName,
        reason:reason,
    }

    let res = await Parse.Cloud.run("rejectCertificationFile",data);
   
    return res

}
export const createAgent = async (Lastname, hashedPassword, Name, Department, email,User=null,Business=null)=>{
    let data = {
        Lastname:Lastname,
        hashedPassword:hashedPassword,
        Name:Name,
        Department:Department,
        email:email,
        User:User,
        Business:Business
    }

    let res = await Parse.Cloud.run("createAgent",data);
   
    return res

}
export const buyJobForWoller = async (jobId, joberId, sessionToken) => {
    try {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let res = await Parse.Cloud.run("buyJobForJober", {
            jobId: jobId,
            joberId: joberId,
            sessionToken: sessionToken ? sessionToken : null
        });
        return res;
    } catch (error) {
        console.error(error)
    }
}
export const searchJobsByName = async (jobName, joberId=null,userType=null,jobType) => {
    let res = await Parse.Cloud.run("searchJobsByName", {
        jobName: jobName,
        joberId: joberId,
        userType:userType,
        jobType:jobType
    });
    
    return res;
}
export const holdStandBy = async ()=>{
   
    let res = await Parse.Cloud.run("holdStandBy");
   
    return res
}
export const createOpsNotification = async (jobId, description, typology) => {
    let data = {
        jobId: jobId,
        comment: description,
        typology: typology
    }
    let res = await Parse.Cloud.run("createOpsNotification", data);
    return res;
}
export const deleteOpsNotification = async (notificationId) => {

    let data = {
        notificationId: notificationId
    }
    
    let res = await Parse.Cloud.run("deleteOpsNotification", data);
    return res;
}
export const changeOpsNotificationState = async (notificationId, newState) => {

    const data = {
        notificationId: notificationId,
        newState: newState
    }
    
    let res = await Parse.Cloud.run("changeOpsNotificationState", data);
    return res;
}
export const changeOpsNotificationDescription = async (notificationId, description) => {

    const data = {
        notificationId: notificationId,
        description: description,
    }
    
    let res = await Parse.Cloud.run("changeOpsNotificationDescription", data);
    return res;
}
export const searchJobsByUserData = async (name,phone,business,jobType) => {

    const data = {
        name:name,
        phone:phone,
        businessId:business,
        jobType:jobType
    }
        
    let res = await Parse.Cloud.run("searchJobsByUserData", data);
    return res;
}
export const modifyJobLeadPrice = async (jobId, price) => {

    const data = {
        jobId: jobId,
        price: price
    }

    let res = await Parse.Cloud.run("modifyJobLeadPrice", data);
    return res;
}
export const searchTableControlOpsData = async (startDate, endDate) => {

    const data = {
        startDate: startDate._d,
        endDate: endDate._d
    }

    let res = await Parse.Cloud.run("searchTableControlOpsData", data);

    return res;
}
export const processECIExcel = async (jobs) => {

    const data = {
        jobs: jobs
    }

    let res = await Parse.Cloud.run("processECIExcel", data);

    return res;
}
export const fetchMatchs = async ({startDate,endDate,business,startServiceDate,endServiceDate,agents,provinces,page,limit}) => {
    const data = {
        startDate:startDate,
        endDate:endDate,
        business:business,
        startServiceDate:startServiceDate,
        endServiceDate:endServiceDate,
        agents:agents,
        provinces:provinces,
        page:page,
        limit:limit
    }

    let res = await Parse.Cloud.run("fetchMatchs", data);

    return res
}
export const createJoberTask = async ({wollerId,verticalId,type}) => {
    const data = {
        joberId:wollerId,
        verticalId:verticalId,
        type:type
    }

    let res = await Parse.Cloud.run("createJoberTask", data);

    return res
}
export const deleteJoberTask = async ({wollerId,verticalId,type}) => {
    const data = {
        joberId:wollerId,
        verticalId:verticalId,
        type:type
    }

    let res = await Parse.Cloud.run("deleteJoberTask", data);

    return res
}
export const addJobsImages = async ({wollerId,photos}) => {
    const data = {
        wollerId:wollerId,
        photos:photos
    }

    let res = await Parse.Cloud.run("addJobsImages", data);

    return res
} 
export const removeJobsImages = async ({wollerId,file}) => {
    const data = {
        wollerId:wollerId,
        file:file
    }

    let res = await Parse.Cloud.run("removeJobsImages", data);

    return res
} 
export const generatePaymentLink = async ({priceId,isFromWeb=false,userAccount=false,jobid=false})=> {
    const data = {
        priceId:priceId,
        isFromWeb:isFromWeb,
        userAccount:userAccount,
        jobid:jobid,
    }

    let res = await Parse.Cloud.run("generatePaymentLink",data);

    return res
}
export const createPaymentInProcess = async ({packId,jober=false,from=false,paymentLink=false})=> {
    const data = {
        packId:packId,
        jober:jober,
        from:from,
        paymentIntentId:paymentLink,
    }

    let res = await Parse.Cloud.run("createPaymentInProcess",data);

    return res
}
export const uploadDocument = async ({document,documentName,format,type,wollerId}) => {

    const data = {
        document:document,
        type:type,
        documentName:documentName,
        format:format,
        wollerId:wollerId,
    }
    let res = await Parse.Cloud.run("uploadDocuments",data);

    return res
}
export const processPostPay = async ({ammount,userId,jobId,sessionToken}) => {

    const data = {
        ammount:ammount,
        userId:userId,
        jobId:jobId,
        sessionToken:sessionToken
    }
    let res = await Parse.Cloud.run("processPostPay",data);

    return res
}
export const uploadFilesFromJob = async (photos,jobId) => {
    let res = await Parse.Cloud.run("uploadFilesFromJob",{photos:photos,jobId:jobId});
    return res
}
export const createJober = async ({phone,name,email,nif}) => {
    try {
        const userId = await Parse.Cloud.run("createJober", {
          phone: phone,
          joberName: name,
          email: email,
          nif:nif
        });
        return {
          status: "ok",
          result: await new Parse.Query(Parse.User).get(userId)
        };
    } catch (error) {
        console.error(error);
        return {
            status: "error",
            result: "Error al registrar el nuevo usuario."
        };
    }
}
export const publishProManagerService = async ({jobId}) => {
    try {
        return await Parse.Cloud.run("publishFixedDraftProManager", {jobId:jobId});
    } catch (error) {
        console.error(error);
        return {
            status: "error",
            result: "Error al intentar Publicar el servicio."
        };
    }
}
export const searchBusiness = async (businessIds) => {
    let businessQuery = new Parse.Query("Business").containedIn("objectId",businessIds);
    try {
        const business = await businessQuery.find();
        if (business) {
            return {
                status: 'ok',
                result: business
            };
        } else {
            return {
                status: 'error',
                result: "Algo ha fallado y no hemos podido obtener los negocios principales."
            };
        }
    } catch (error) {
        console.error("Error al buscar business:", error);
        return [];
    }
};
export const createNewRefuseNotification = async (jobId, user, textAnulationReason) => {
    Parse.masterKey = masterKey;
    try {
        const jobQuery = new Parse.Query(Parse.Object.extend("Jobs"));
        jobQuery.equalTo("objectId", jobId);
        const job = await jobQuery.first();

        const crmUserQuery = new Parse.Query(Parse.Object.extend("CRMUser"));
        crmUserQuery.equalTo("User", Parse.User.createWithoutData(user.goldId))
        const crmUser = await crmUserQuery.first();

        const crmHistory = Parse.Object.extend("CRMHistory");
        const history = new crmHistory();
        history.set("CRMUser", crmUser);
        history.set("Job", job);
        history.set("Type", "refuseNotification");
        history.set('ExtraInfo', { 'newNotes': textAnulationReason });
        let result = await history.save(null, { useMasterKey: true });

        //En el segundo rechazo ya se activa la solicitud de asistencia
        const crmHistoryQuery = new Parse.Query(Parse.Object.extend("CRMHistory"));
        crmHistoryQuery.equalTo("Job", Parse.Object.extend("Jobs").createWithoutData(jobId));
        crmHistoryQuery.equalTo("Type", "refuseNotification");
        const refuseNotifications = await crmHistoryQuery.count();
        if(refuseNotifications == 2){
            await changeRequestedAssistance(jobId);
        }

        return {
            status: "ok",
            result: result
        };
    } catch (error) {
        console.error("Error creating refuse notification:", error);
    }
}
export const changeRequestedAssistance = async (jobId) => {
    try {
        const fixedInfoQuery = new Parse.Query(
            Parse.Object.extend("FixedInfo"));
        fixedInfoQuery.equalTo("Job", Parse.Object.extend("Jobs").createWithoutData(jobId));
        const fixedInfo = await fixedInfoQuery.first();
        if (fixedInfo) {
            fixedInfo.set("RequestedAssistance", true);
            return {
                status: "ok",
                result: await fixedInfo.save(null, {useMasterKey: true})
            };
        }
    } catch (error) {
        console.error("Error saving FixedInfo:", error);
        return {
            status: "error",
            message: error.message
        };
    }
}
export const resetAJob = async (jobId) => {
    Parse.masterKey = masterKey;
    try {
        const jobQuery = new Parse.Query(Parse.Object.extend("Jobs"));
        jobQuery.equalTo("objectId", jobId);
        const job = await jobQuery.first();

        job.set("AssignedTo", undefined);
        job.set("PriceMax", 0);
        job.set("State", "draft");
        await job.save(null, { useMasterKey: true });

        const fixedInfoQuery = new Parse.Query(Parse.Object.extend("FixedInfo"));
        fixedInfoQuery.equalTo("Job", job);
        const fixedInfo = await fixedInfoQuery.first();

        fixedInfo.set("BasicPrice", 0);
        fixedInfo.set("JoberPrice", 0);
        fixedInfo.unset("Appointment");
        fixedInfo.unset("Jober");
        return {
            status: "ok",
            result: await fixedInfo.save(null, { useMasterKey: true })
        };
    } catch (error) {
        console.error("Error resetting job:", error);
    }
}
