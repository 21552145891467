import React, { useEffect, useState } from "react";
import MainModal from "./MainModal"

import "../../styles/silgleComponents/range.css"

const IvaModal = ({ rangeOptions = [1.21], isVisible, onClose , onSaveNewIva,currentValue}) => {
    const [range, setRange] = useState(rangeOptions.indexOf(currentValue)||0)
    const [disabled, setDisabled] = useState(true)
    return (

        <MainModal
            big={true}
            title={"¿Que porcentaje de IVA deseas aplicar a este servcio?"}
            subtitle={""}
            isVisible={isVisible}
            onClose={() => onClose()}
        >
            <div className="IVA rangeContainer">
                <input type="range" min="0" max={rangeOptions.length - 1} defaultValue={range} onChange={(e) => { setRange(e.target.value);console.log(e.target.value); setDisabled(false) }}></input>{Math.trunc((rangeOptions[range] * 100)-100)}%
            </div>
            <button onClick={()=>onSaveNewIva(rangeOptions[range])} className="edit-fixed-price-modal-button" disabled={disabled}>Guardar</button>

        </MainModal>
    )
}

export default IvaModal;