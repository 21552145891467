import React, { useEffect, useState, useRef } from "react";
import Jobin from "jobin-client";
import GoogleMapReact from "google-map-react";
import CurrencyInput from 'react-currency-input-field'; 
import { useStateValue } from "../../../../state/context";

import InfoCell from "../InfoCell";
import EditExtrasModal from "../../../modal/EditExtrasModal";

import SelectBusinessModal from "../../../modal/SelectBusinessModal";
import SelectAgentModal from "../../../modal/SelectAgentModal";
import Button from "../../../button/Button";
import {
    editJobData,
    getSugestedPrice,
    updateVerified,
    getFatherVerticals as getFatherVerticals_,
    findGuildByBusiness as findGuildByBusiness_,
    getNotificationsPhotos as getNotificationsPhotos_,
    checkIfWollerBuyJob as checkIfWollerBuyJob_,
    updateTypeJobs,
    updateACRGuild,
    updateACRDefects,
    getAllJobs,
    updateDescription,
    updateSugestedPrice, getAddressFromJob
} from "../../../../utils/ParserUtilities";
import ShowPhotosModal from "../../../modal/ShowPhotosModal"

import {
    wollersFrontUrl, wollyProUrl
} from '../../../../constants/credentials';
import {
    formatState,
    formatDateWhitoutHour,
    getFormattedAddressDetail
} from '../../../../utils/formatter'
import "../../../../styles/search/detail/job-detail-container.css";
import VerticalModal from "../../../modal/VerticalModal";
import { useCrypto } from "../../../../utils/crypto";


export default function DetailContainer(props) {
    const [{ user, currentSession, agent, jobDetails}, dispatch] = useStateValue();
    const { encrypt } = useCrypto();
    const [includeArray, setIncludeArray] = useState([]);
    const [nonIncludeArray, setNonIncludeArray] = useState([]);
    const [fixedInfo, setFixedInfo] = useState(null);
    const [transportInfo, setTransportInfo] = useState(null);
    const [relatedJob, setRelatedJob] = useState(null);
    const [business, setBusiness] = useState(null);
    const [newAgent, setAgent] = useState(null);
    const [shopLocation, setShopLocation] = useState(null);
    const [showBusiness, setShowBusiness] = useState(false);
    const [showPromotions, setShowPromotions] = useState(false);
    const [showAgent, setShowAgent] = useState(false);
    const [showVerticalModal, setShowVerticalModal] = useState(false);
    const [typeService, setTypeService] = useState(null);
    const [typeGuild, setTypeGuild] = useState(null);
    const [typeDefect, setTypeDefect] = useState(null);
    const [optionsService, setOptionsService] = useState([]);
    const [optionsGuilds, setOptionsGuilds] = useState([]);
    const [jobdId, setJobId] = useState(null);
    const [descriptionJobs, setDescriptionJobs] = useState(null);
    const [sugestedPrice, setSugestedPrice] = useState(null);
    const [verified, setVerified] = useState(false);
    const [fixedPhotos, setFixedPhotos] = useState(null);
    const [showPhotos, setShowPhotos] = useState(false);
    const [photosToShow, setPhotosToShow] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [isCopiedOld, setIsCopiedOld] = useState(false);
    const [addresDetail, setAddresDetail] = useState();

    const [showEditExtrasModal, setShowEditExtrasModal] = useState(false);
    const [extrasArray, setExtrasArray] = useState(jobDetails.extrasArray);
    const [jobWollerNotification, setJobWollerNotification] = useState(undefined);



    useEffect(() => {
        if (jobDetails.transportInfo) {
            async function getTransportInfo() {
                const vertical = jobDetails.transportInfo.get("Vertical");
                const result = await Jobin.Guild.getFromId(vertical.id);
                if (result.status === "ok") {
                    setShopLocation(result.result.get("Location"));
                }
            }
            if (jobDetails.transportInfo.get("RelatedTo")) {
                async function getRelatedJob() {
                    const result = await Jobin.Job.getRelatedJob(
                        jobDetails.transportInfo
                    );
                    if (result.status === "ok") {
                        setRelatedJob(result.result);
                    }
                }
                getRelatedJob();
            }
            getTransportInfo();
            setTransportInfo(jobDetails.transportInfo);
        } else if (jobDetails.fixedInfo) {
            async function getIncludes() {
                const result = await Jobin.Job.getIncludes(jobDetails.job);
                if (result.status === "ok") {
                    setIncludeArray(result.result);
                }
            }
            async function getNonIncludes() {
                const result = await Jobin.Job.getNonIncludes(
                    jobDetails.job
                );
                if (result.status === "ok") {
                    setNonIncludeArray(result.result);
                }
            }
            if (jobDetails.fixedInfo.get("RelatedTo")) {
                async function getRelatedJob() {
                    const result = await Jobin.Job.getRelatedJob(
                        jobDetails.fixedInfo
                    );
                    if (result.status === "ok") {
                        setRelatedJob(result.result);
                    }
                }
                getRelatedJob();
            }
            getIncludes();
            getNonIncludes();
            setFixedInfo(jobDetails.fixedInfo);
        }

        async function getBusiness() {
            const business = jobDetails.job.get("Business");
            if (business) setBusiness(await business.fetch());
            let leadPrice = await getSugestedPrice(jobDetails.job.id)
            setSugestedPrice(leadPrice)
        }
        getBusiness();
        if (jobDetails.job.has("AssignedTo")) {
            jobDetails.job.get("AssignedTo").fetch().then(newAgent => setAgent(newAgent));
        }
        setTypeService(jobDetails.job.get("Type"));
        setDescriptionJobs(jobDetails.job.get("Description"));
     
        
        getAllJobs();
        getFatherVerticals();
        getGuildsAndDefects();
        setJobId(jobDetails.job.id);
        setVerified(jobDetails.job.get("Verified"));

        if(user.isGoldWoller){
            checkIfWollerBuyJob(jobDetails.job.id);
        }

        getNotificationsPhotos(jobDetails.job.id);
        getAddressDetail(jobDetails.job.id);

    }, []);

    useEffect(()=>{
        if(photosToShow.length > 0) setShowPhotos(true);
    },[photosToShow])

    const saveExtras = async extraArr => {
        setShowEditExtrasModal(false);
        setExtrasArray(extraArr);

        const extraPrice = extraArr.reduce((accumulator, extra) => {
            return accumulator + extra.pricePerUnit * extra.units;
        }, 0);

        dispatch({
            type: "JOB_PRICE_EXTRAS",
            extrasArray: extraArr,
            extraAmount: extraPrice
        });
    };
    const getAddressDetail = async(jobId)=> {
       let res = await getAddressFromJob(jobId);
       if (res) {
           const formattedAddress = getFormattedAddressDetail(res);
           setAddresDetail(formattedAddress);
       }
    };

    const title = () => {
        const jobType = jobDetails.job.get("JobType");
        switch (jobType) {
            case "lead":
                return "Lead";
            case "fixed":
                return "Precio cerrado";
            case "transport":
                return "Transporte";
            case "bid":
                return "Subasta";
            default:
                return "Trabajo";
        }
    };

    const handleGoogleMapApi = google => {
        let directionsService = new google.maps.DirectionsService();
        var directionsDisplay = new google.maps.DirectionsRenderer();
        directionsDisplay.setMap(google.map);

        directionsService.route(
            {
                travelMode: "DRIVING",
                origin: new google.maps.LatLng(
                    shopLocation.latitude,
                    shopLocation.longitude
                ),
                destination: new google.maps.LatLng(
                    transportInfo.get("Location").latitude,
                    transportInfo.get("Location").longitude
                )
            },
            (DirectionsResult, DirectionsStatus) => {
                if (DirectionsStatus === "OK") {
                    directionsDisplay.setDirections(DirectionsResult);
                }
            }
        );
    };

    const getFatherVerticals = async () => {
       let res = await getFatherVerticals_()
        let arrayServices = [];
        res.map(element => {
                arrayServices.push(element.get("Type"))
        })
        setOptionsService(arrayServices);
    }

    const getGuildsAndDefects = async () => {
        let businessId = agent?.business?.id
        if(businessId){

            let res = await findGuildByBusiness_({businessId:businessId})
            let guild = jobDetails.fixedInfo?.get("Guild")
            let defect = jobDetails.fixedInfo?.get("Defect")
    
            let selectedGuild = guild ? res.map((guild_)=>{
                if(guild.id == guild_.id ){
                    return guild_
                }
            }).filter(x=>x).pop():res[0]
    
    
            let selectedDefect = defect ? 
            selectedGuild?.defects.filter(defect_=>defect_.id == defect.id).pop()
                : selectedGuild.defects.filter((defect_)=> defect_.default).pop()
    
            
            
            setOptionsGuilds(res);
            setTypeGuild(selectedGuild)
            setTypeDefect(selectedDefect)
        }
        
    }

    const getNotificationsPhotos = async (id) => {
      
        let res = await getNotificationsPhotos_(id);
        setFixedPhotos(res)
    }
    
    const handleChangeSelect = async (event) => {
        
        let data = {
            jobId: jobdId,
            typeService: event.target.value,
            crmId: agent ? agent.agentId ? agent.agentId : null : null
        }
        await updateTypeJobs( data);
    }
    const handleChangeSelectGuild = async (event) => {
        let guild = optionsGuilds.filter(guild_=>guild_.id == event.target.value).pop()
        setTypeGuild(guild)
        try {
            let data = {
                jobId: jobdId,
                typeService: guild.vertical.name,
                crmId: agent ? agent.agentId ? agent.agentId : null : null
            }
            let res = await updateTypeJobs(data);
            setTypeService(res.get("Type"));
            data = {
                jobId: jobdId,
                guildId: guild.id,
            }
            await updateACRGuild(data); 
            data = {
                jobId: jobdId,
            }
            await updateACRDefects(data); 
        } catch (error) {
            console.error(error)
        }
       
    }
    const handleChangeSelectDefect = async (event) => {
        let defect = typeGuild.defects.filter(defect_=>defect_.id == event.target.value).pop()
        setTypeDefect(defect)

        let data = {
            jobId: jobdId,
            defectId: defect.id,
        }
        let res = await updateACRDefects( data);//edit defect only
    }

    const checkIfWollerBuyJob = async (id) => {

        let data = {
            jobId: id,
            userId: currentSession?.wollerData?.id
        }
        let res = await checkIfWollerBuyJob_(data);
        if(res){
            setJobWollerNotification(res);
        }
    }

    const handleChangeSelectVerified = async (event) => {
        
        let res = await updateVerified(jobdId, event.target.value == "true" ? true :  false);
    }

    const handleChangeDescription = async (event) => {
        setDescriptionJobs(event.target.value);
    }
    const handleChangeSugestedPrice = async (value) => {
         setSugestedPrice(value);
    }

    const saveChangeDescription = async () => {

        let data = {
            id: jobdId,
            description: descriptionJobs
        }
        let res = updateDescription(data);
    }

    const saveChangeSugestedPrice = async () => {

        let newData = {
            jobId: jobdId,
            sugestedPrice: sugestedPrice
        }
        //modificacion especial eliminar todos los agentId diferentes a  HhjJLJTauL
        if(sugestedPrice >= 3 || agent.dept == "CEO" || 
        agent.agentId == "HhjJLJTauL"|| 
        agent.agentId == "mkmHlYHisJ"|| 
        agent.agentId == "dDDC1Hd530"|| 
        agent.agentId == "EKtCUAxwTK"|| 
        agent.agentId == "gsvYzRYYaJ"|| 
        agent.agentId == "jAYoHh1ckL"|| 
        agent.agentId == "5dQhlwhprl" ||
        agent.agentId == "vQD7dZqdfL" ||
        agent.agentId == "gdMnCpCtux"){
            let res = await updateSugestedPrice(newData);
        } else {
            alert("No se puede cambiar el precio de un trabajo menor a 3 euros");
        }
    }

    const selectBusiness = async business => {
        setBusiness(business);
        setShowBusiness(false);
        setShowPromotions(false);
        const result = await Jobin.Business.setBusinessForJob(
            business,
            jobDetails.job
        );
        if (result.status === "ok") {
            dispatch({
                type: "JOB_DETAILS_JOB",
                job: await jobDetails.job.fetch(),
            });
        } else {
            setBusiness(null);
            setShowBusiness(false);
            setShowPromotions(false);
            alert(result.result);
        }
    };

    const selectAgent = async agent => {
        const {status, result} = await Jobin.Job.reassignJob(
            jobDetails.job,
            agent
        );
        if (status === "ok") {
            setAgent(agent);
            setShowAgent(false);
            dispatch({
                type: "JOB_DETAILS_JOB",
                job: await jobDetails.job.fetch(),
            });
        } else {
            setAgent(null);
            setShowAgent(false);
            alert(result);
        }
    };

    const handleTexChange = async (event) => {
        let dataParsed={
            key:event.target.name,
            value:event.target.value
        }

        let response = await editJobData (dataParsed, jobDetails.job.id)
    };
    const removeFile = async e => {
        const filename = e.target.getAttribute("data-filename");
        await Jobin.Job.removeFileFromJob(jobDetails.job.id, filename);
        window.location.reload();
    };

    const handleCopyClick = () => {
      if (!jobDetails.readMode) {
        const joberId = fixedInfo.get("Jober").id;
        const timestamp = Date.now();

        encrypt(`${timestamp}_${joberId}`).then((token) => {
          const url = `${wollyProUrl}mi-servicio/${jobdId}?token=${token}`;
          navigator.clipboard.writeText(url);
          setIsCopied(true);
        });
      }
    };


    const handleAsistentePresupuestoClick = () => {
        let verticalText =  jobDetails.job.get("Type");
        if ( jobDetails.job.get("Subvertical")  && jobDetails.job.get("Subvertical").get("Type") ) {
            verticalText += ` ${jobDetails.job.get("Subvertical").get("Type")}`; 
        }
        if (jobDetails.job.get("Description")) {
            verticalText += ` - ${jobDetails.job.get("Description")}`;
        }
        let locality = jobDetails.job.get("Address");
        const textAdvice = `¿Cuál sería el presupuesto para un trabajo de ${verticalText} en ${locality}?`;
        window.open("https://wa.me/34651973584?text="+textAdvice, "_blank");
    }

    const getPromotionName = () => {
        const matchedPromotion = currentSession.contractor.promotions.find(promotion => promotion.business === business?.id);
        return matchedPromotion ? matchedPromotion.name : "Sin negocio asignado";
    };

    return (
        <section className="jobDetailContainer">
            {showPhotos && (
                    <ShowPhotosModal
                        isVisible={true}
                        onClose={() => setShowPhotos(false)}
                        photos={photosToShow}
                        title={"Fotografías y vídeos"}
                    />
                )}
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    overflow: "hidden"
                }}
            >
                <label className="subTitle">
                    {!user?.isGoldWoller && (<label onClick={() => setShowVerticalModal(true)} style={{ color: '#3543FF', cursor: 'pointer' }}></label>)}
                    <label>{ title() }</label>
                    {
                        showVerticalModal && (
                            <VerticalModal
                                onClose={() => setShowVerticalModal(false)}
                            />
                    )}
                </label>
                <label className="title">
                    {jobDetails.job.get("Title")}
                </label>
                {
                    !user?.isMediator && (
                        <p>{agent?.dept =="ConstructionCompany" ? "Gremio Wolly:":"Gremio:"}</p>
                    )
                }
                <div>
                    {user.permissions && user.permissions.job.edit ?(
                        <select 
                            value={typeService ?? ""}
                            disabled={user?.isGoldWoller || agent?.dept =="ConstructionCompany" }
                            onChange={!user?.isGoldWoller ? handleChangeSelect:null}
                            style={{
                                width: '100%',
                                padding: '5px',
                                border: '1px solid #E3E3E3',
                                borderRadius: '4px'
                            }}
                        >  
                        {
                            optionsService.map(element => {
                                return (<option key={element}>{element}</option>)
                            })
                        }
                        
                    </select>):
                    (<h5 className="margin-top-0 ">{typeService}</h5>)}
                </div>
                {
                    agent?.dept =="ConstructionCompany"  && (
                        <p>{agent?.dept =="ConstructionCompany" ? `Gremio ${currentSession?.contractor.name}:`:"Gremio:"}</p>
                    )
                }
                {agent?.dept =="ConstructionCompany" && typeGuild  && (
                    <div>
                        <select 
                            value={typeGuild.id}
                            disabled={jobDetails.readMode}
                            onChange={ handleChangeSelectGuild}
                            style={{
                                width: '100%',
                                padding: '5px',
                                border: '1px solid #E3E3E3',
                                borderRadius: '4px'
                            }}
                        >  
                        {
                            optionsGuilds.map(element => {
                                return (<option key={element.id} value={element.id}>{element.name}</option>)
                            })
                        }
                        
                        </select>
                    </div>)}
                {
                    agent?.dept =="ConstructionCompany"  && (
                        <p>{currentSession?.contractor ?`Defecto ${currentSession?.contractor?.name}:`:"Defecto"}</p>
                    )
                }
                {agent?.dept =="ConstructionCompany"  && typeGuild?.defects && typeDefect && (
                    <div>
                        <select 
                            value={typeDefect.id}
                            disabled={jobDetails.readMode}
                            onChange={ handleChangeSelectDefect}
                            style={{
                                width: '100%',
                                padding: '5px',
                                border: '1px solid #E3E3E3',
                                borderRadius: '4px'
                            }}
                        >  
                        {
                            typeGuild?.defects.map(element => {
                                return (<option key={element.id}  value={element.id} >{element.name}</option>)
                            })
                        }
                        
                        </select>
                    </div>)}
                {
                    !user?.isMediator && agent?.dept !== "ConstructionCompany" && (
                        <p>Verified:</p>
                    )
                }
                <div>
                    {user.permissions && user.permissions.job.edit && agent?.dept !== "ConstructionCompany" ? (<select
                        value={verified} 
                        disabled={jobDetails.readMode || user?.isGoldWoller }
                        onChange={!user?.isGoldWoller ? handleChangeSelectVerified :null}
                        style={{
                            width: '100%',
                            padding: '5px',
                            border: '1px solid #E3E3E3',
                            borderRadius: '4px'
                        }}
                    >  
                        <option key={true}>{'true'}</option>
                        <option key={false}>{'false'}</option>
                    </select>):
                    (<h5 className="margin-top-0 ">{verified}</h5>)}
                </div>
            </div>
            <div className="detailContainer">
                <InfoCell
                    title="ID del trabajo"
                    data={
                        fixedInfo
                            ? fixedInfo.get("ID")
                            : transportInfo
                            ? transportInfo.get("ID")
                            : jobDetails.job.id
                    }
                    halfLeft={true}
                />
                <InfoCell
                    title="Estado del trabajo"
                    data={formatState(jobDetails.job)}
                    halfRight={true}
                />
            </div>
            {
                jobDetails.job.get("externalId") && (
                    <InfoCell
                        title="ID Externo del partner"
                        data={jobDetails.job.get("externalId")}
                    />
                )
            }
            <InfoCell
                title="Negocio asignado"
                data={currentSession.contractor ? currentSession.contractor.name : business ? business.get("Name") : "Sin negocio asignado"}
                logo={
                    user && user.business
                        ? user.business.logo
                        : null
                }
                optionText="Editar"
                optionAction={!currentSession.contractor && (!user?.isGoldWoller && user?.permissions?.job.edit) ?() => setShowBusiness(true):null}
            />
            {
                jobWollerNotification && (
                    <InfoCell
                        title="Telefono"
                        data={jobWollerNotification.get("ToUser").get("Phone")}
                    />
                )
            }
            {showBusiness && (
                <SelectBusinessModal
                    isVisible={showBusiness}
                    onClose={() => setShowBusiness(false)}
                    selectBusiness={selectBusiness}
                />
            )}
            {agent?.dept =="ConstructionCompany" && (<InfoCell
                title="Promoción"
                data={getPromotionName()}
                logo={
                    business && business.get("Logo")
                        ? business.get("Logo").url()
                        : null
                }
                optionText="Editar"
                optionAction={!jobDetails.readMode && (!user?.isGoldWoller && user?.permissions?.job.edit) ?() => setShowPromotions(true):null}
            />)}
            {showPromotions && (
                <SelectBusinessModal
                    isVisible={showPromotions}
                    onClose={() => setShowPromotions(false)}
                    selectBusiness={selectBusiness}
                    businessType="promotions"
                />
            )}
            {!user?.isMediator && agent?.dept !== "ConstructionCompany" && (
                <InfoCell
                    title="Agente asignado"
                    data={
                        newAgent && newAgent.has("Lastname")
                            ? newAgent.get("Name") + ' ' + newAgent.get("Lastname")
                            : newAgent
                            ? newAgent.get("Name")
                            : "Sin agente asignado"
                    }
                    optionText="Editar"
                    optionAction={!jobDetails.readMode && (!user?.isGoldWoller && user?.permissions?.job.assign) ? () => setShowAgent(true) : undefined}
                />
            )}
            {showAgent && agent?.dept !== "ConstructionCompany" && (
                <SelectAgentModal
                    isVisible={showAgent}
                    onClose={() => setShowAgent(false)}
                    selectAgent={selectAgent}
                />
            )}
            {jobDetails.job.get("Priority") === "urgent" && (
                <InfoCell
                    title="Urgencia"
                    data={"Servicio urgente"}
                    logo={
                        require("../../../../assets/urgent-selected.svg")
                    }
                />
            )}
            {showAgent && agent?.dept === "ConstructionCompany" && (
                <InfoCell
                title="Fecha de creación"
                data={Jobin.Date.toLargeFormat(jobDetails.job.createdAt).result}
                />
            )}
            {agent?.dept !== "ConstructionCompany" && (
                <div className="detailContainer">
                    <InfoCell
                        title="Nivel"
                        data={
                            jobDetails.job.get("Level") === 4
                                ? "Platino"
                                : jobDetails.job.get("Level") === 3
                                ? "Oro"
                                : jobDetails.job.get("Level") === 2
                                ? "Plata"
                                : "Bronce"
                        }
                        logo={
                            jobDetails.job.get("Level") === 4
                                ? require("../../../../assets/badge-platinum.svg")
                                : jobDetails.job.get("Level") === 3
                                ? require("../../../../assets/badge-gold.svg")
                                : jobDetails.job.get("Level") === 2
                                ? require("../../../../assets/badge-silver.svg")
                                : require("../../../../assets/badge-bronce.svg")
                        }
                        halfLeft
                    />
                    <InfoCell
                        title="Fecha de creación"
                        data={
                            Jobin.Date.toLargeFormat(jobDetails.job.createdAt)
                                .result
                        }
                        halfRight
                    />
                    {!fixedInfo && (
                        <button className="btn_asistentePresupuesto" onClick={handleAsistentePresupuestoClick}>Asistente presupuesto</button>   
                    )}
                    
                </div>
            )}
            {fixedInfo && agent?.dept !== "ConstructionCompany" && (
                <InfoCell
                    title="Cita preferible"
                    data={
                        fixedInfo.get("AvailableTo")
                            ? Jobin.Date.toSortFormat(
                                    fixedInfo.get("AvailableTo")
                                ).result
                            : "Sin fecha"
                    }
                />
            )}
            {relatedJob && (
                <InfoCell
                    title="Trabajo relacionado"
                    data={relatedJob.id}
                    optionText="Ver"
                    optionAction={() => {
                        props.history.push({
                            pathname: "/search/" + relatedJob.id,
                            search: props.location.search
                        });
                    }}
                />
            )}
            {transportInfo && (
                <div className="detailContainer">
                    <InfoCell
                        title="Distancia"
                        data={transportInfo.get("Distance") + " Km"}
                        halfLeft={true}
                    />
                    <InfoCell
                        title="Peso"
                        data={transportInfo.get("Weight") + " Kg"}
                        halfRight={true}
                    />
                </div>
            )}
            <div className={["display-flex","flex-column"]} >
                <label style={{color: '#B0B0B0', fontSize: '12px', marginTop: '8px', width:"100%"}}>Localización</label>
                    <input
                    disabled={jobDetails.readMode || (user?.isMediator ? user?.isMediator : user?.isGoldWoller && user?.permissions?.job?.edit)}
                    className="textInput"
                    type="text" 
                    style={{
                        width: '100%',
                        padding: '5px',
                        border: '1px solid #E3E3E3',
                        borderRadius: '4px',
                        marginRight:"auto"
                    }} 
                    defaultValue={jobDetails.job.get("Address") }
                    name="Address"
                    onBlur={handleTexChange}
                
                    ></input>
                    
                </div>
           { addresDetail && <div className={["display-flex","flex-column"]} >
                <label style={{color: '#B0B0B0', fontSize: '12px', marginTop: '8px', width:"100%"}}>Detalles de la localización</label>
                    <input
                    disabled={true}
                    className="textInput"
                    type="text" 
                    style={{
                        width: '100%',
                        padding: '5px',
                        border: '1px solid #E3E3E3',
                        borderRadius: '4px',
                        marginRight:"auto"
                    }} 
                    defaultValue={addresDetail}
                    name="Address"
                    onBlur={handleTexChange}
                
                    ></input>
                    
                </div>}
            <label style={{color: '#B0B0B0', fontSize: '12px', marginTop: '8px'}}>Descripción</label>
            <textarea
            style={{
                width: '100%',
                padding: '5px',
                border: '1px solid #E3E3E3',
                borderRadius: '4px',
                minHeight: '80px',
                resize: 'none'
            }} 
            disabled={jobDetails.readMode || (user?.isMediator ? user?.isMediator : user?.isGoldWoller && user?.permissions?.job?.edit)}
            value={descriptionJobs ?? ""}
            onChange={handleChangeDescription}
            onBlur={saveChangeDescription}
            ></textarea>
           
            {jobDetails.job.get("JobType") == "lead" && (<section  style={{
                display:"flex",
                flexDirection: "column",
                paddingBottom: "8px",
                borderBottom: "1px solid var(--soft-grey)",
                overflow: "hidden"
                
                }}>
                {!user.isB2b && user?.permissions?.job?.create && !user?.isMediator && (
                <>
                    <label style={{color: '#B0B0B0', fontSize: '12px', marginTop: '8px', width:"100%"}}>Precio</label>
                    <div style={{display:"flex"}} >
                        <CurrencyInput
                            id="input-example"
                            name="input-name"
                            placeholder="Please enter a number"
                            defaultValue={0}
                            value={sugestedPrice}
                            intlConfig={{ currency: 'EUR' }}
                            decimalsLimit={2}
                            onValueChange={handleChangeSugestedPrice}
                            style={{
                                width: '82%',
                                padding: '5px',
                                border: '1px solid #E3E3E3',
                                borderRadius: '4px',
                                marginRight:"auto"}
                            }
                            disabled={user?.isGoldWoller ? true : (user.permissions.job && !user?.permissions?.job.edit)}
                        />
                    
                        {!user?.isGoldWoller && user.permissions.job && user?.permissions?.job.edit && (<div className="buttonContainer">
                            <Button
                                buttonText="Editar"
                                style={{ width: 85, color: "var(--coral)" }}
                                onClick={saveChangeSugestedPrice}
                            />
                        </div>)}
                    </div>
                </>)}
            </section>)}
            {jobDetails.job.get("Images") && (
                <InfoCell
                    title="Imágenes"
                    imagesData={jobDetails.job.get("Images")}
                />
            )}
            {fixedInfo && (
                <>
                
                <div>
                    {fixedInfo && fixedInfo.get("Jober") && (
                      <>
                        <p style={{fontWeight: "bold"}}>URL para dar de alta imágenes</p>
                        <div className="f-row" style={{marginTop: 10, marginBottom: 10, fontWeight: "bold"}}>
                            <button className="fixedPhotos-copy" onClick={handleCopyClick}>{!isCopied ? "¡Copiar enlace nuevo!":"Copiado!"}</button>
                            <button className="fixedPhotos-old-copy" onClick={!jobDetails.readMode ? () => {navigator.clipboard.writeText(wollersFrontUrl + `upload-photos?${jobdId}?${fixedInfo.get("Jober").id}`); setIsCopiedOld(true)}:null}>{!isCopiedOld ? "Copiar enlace antiguo":"Copiado!"}</button>
                        </div>
                      </>
                    )}
                    <div><hr></hr><p style={{fontWeight: "bold"}}>Evidencias</p></div>
                    {fixedPhotos && fixedPhotos.preImages && fixedPhotos.preImages.length && (
                        <>
                            <div className="f-row margin-top">
                                <div className="f-row-left">
                                    <div class="badge-total">{fixedPhotos.preImages.length}</div>
                                    <p className="fixedPhotos-title">Imágenes del antes (Woller)</p>
                                </div>
                                <button onClick={() => {setPhotosToShow(fixedPhotos.preImages)}} className="btn-see-details-modal">Ver</button>
                            </div>
                        </>
                    )}
                    {fixedPhotos && fixedPhotos.postImages && fixedPhotos.postImages.length && (
                        <>
                             <div className="f-row  margin-top">
                                <div className="f-row-left">
                                    <div class="badge-total">{fixedPhotos.postImages.length}</div>
                                    <p className="fixedPhotos-title">Imágenes del después (Woller)</p>
                                </div>
                                <button onClick={() => {setPhotosToShow(fixedPhotos.postImages)}} className="btn-see-details-modal">Ver</button>
                            </div>
                        </>
                    )}
                      {fixedPhotos && fixedPhotos.selfieImage && (
                        <>
                            <div className="f-row  margin-top">
                                <div className="f-row-left">
                                    <div class="badge-total">1</div>
                                    <p className="fixedPhotos-title">Selfie del profesional</p>
                                </div>
                                <button onClick={() => {setPhotosToShow([fixedPhotos.selfieImage])}} className="btn-see-details-modal">Ver</button>
                            </div>
                        </>
                    )}
                    {fixedPhotos && fixedPhotos.signature && (
                        <>
                            <div className="f-row margin-top">
                                <div className="f-row-left">
                                    <div class="badge-total">1</div>
                                    <p className="fixedPhotos-title">Firma del cliente proporcionada por el Woller
                                        <br />
                                        Fecha de la firma: {formatDateWhitoutHour(fixedPhotos.date.toString())} 
                                    </p>
                                </div>
                                <button onClick={() => {setPhotosToShow([fixedPhotos.signature])}} className="btn-see-details-modal">Ver firma</button>
                            </div>
                        </>
                    )}
                    {fixedPhotos && fixedPhotos.signaturePro && (
                        <>
                            <div className="f-row margin-top">
                                <div className="f-row-left">
                                    <div class="badge-total">1</div>
                                    <p className="fixedPhotos-title">Firma del Profesional (Woller)
                                        <br />
                                        Fecha de la firma: {formatDateWhitoutHour(fixedPhotos.date.toString())} 
                                    </p>
                                </div>
                                <button onClick={() => {setPhotosToShow([fixedPhotos.signaturePro])}} className="btn-see-details-modal">Ver firma</button>
                            </div>
                        </>
                    )}
                    {fixedInfo.get("Extras") && (
                        <InfoCell
                            title="Extras"
                            dataArray={fixedInfo.get("Extras")}
                            optionText="Editar"
                            optionAction={user.permissions.job && user?.permissions?.job.edit ? () => setShowEditExtrasModal(true) : undefined}
                        />
                        
                    )}
                    {includeArray && (
                        <InfoCell
                            title="¿Qué incluye?"
                            dataArray={includeArray}
                        />
                    )}
                    {nonIncludeArray && (
                        <InfoCell
                            title="¿Qué NO incluye?"
                            dataArray={nonIncludeArray}
                        />
                    )}
                    {fixedInfo.has("AttachedFiles") && (
                        <InfoCell
                            title="Archivos adjuntos"
                            optionAction={removeFile}
                            dataArray={fixedInfo
                                .get("AttachedFiles")
                                .map(file => {
                                    return {
                                        filename: file && file.hasOwnProperty('_name') ? file.name() : "-",
                                        url: file && file.hasOwnProperty('_url') in file ? file.url() : "-"
                                    };
                                })}
                        />
                    )}
                    <EditExtrasModal
                            isVisible={showEditExtrasModal}
                            onClose={() => setShowEditExtrasModal(false)}
                            extrasArray={extrasArray}
                            saveExtras={saveExtras}
                            allowDeleteItems={true}
                            /*subvertical={subvertical}*/
                            /*isUrgent={selectedOption === "urgent"}*/
                           /* basicPrice={job.price.amount}*/
                        />
                </div>
                </>
            )}
            {transportInfo && (
                <div>
                    {transportInfo.has("AttachedFiles") && (
                        <InfoCell
                            title="Archivos adjuntos"
                            optionAction={removeFile}
                            dataArray={transportInfo
                                .get("AttachedFiles")
                                .map(file => {
                                    return {
                                        filename: file && file.hasOwnProperty('_name') ? file.name() : "-",
                                        url: file && file.hasOwnProperty('_url') in file ? file.url() : "-"
                                    };
                                })}
                        />
                    )}
                </div>
            )}
            {transportInfo && shopLocation && (
                <div
                    style={{
                        height: "300px",
                        width: "100%",
                        borderRadius: 4,
                        overflow: "hidden",
                        marginTop: 8,
                        paddingTop: 8,
                        paddingBottom: 8
                    }}
                >
                    <label
                        style={{
                            fontSize: 12,
                            color: "var(--greyish)"
                        }}
                    >
                        Mapa
                    </label>
                    <GoogleMapReact
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={handleGoogleMapApi}
                        bootstrapURLKeys={{
                            key: "AIzaSyDYwng9doYyVkIIyzYLX6crFuJLii07Cus"
                        }}
                        defaultZoom={12}
                        defaultCenter={{
                            lat: transportInfo.get("Location").latitude,
                            lng: transportInfo.get("Location").longitude
                        }}
                    />
                </div>
            )}
        </section>
    );
}
